import { Injectable } from '@angular/core';

import {
  mutationAddUserRoles,
  mutationAddUserScopes,
  mutationAddUserStoreRoles,
  mutationChangeUserPin,
  mutationDeleteUser,
  mutationInviteUser,
  mutationRemoveUserRoles,
  mutationRemoveUserScopes,
  mutationRemoveUserStoreRoles,
  mutationUpdateUser,
  queryIamUser,
  queryUser,
  queryUsers,
  queryUserScopes,
} from '@api';
import {
  MutationAddUserRolesArgs,
  MutationAddUserScopesArgs,
  MutationAddUserStoreRolesArgs,
  MutationDeleteUserArgs,
  MutationInviteUserArgs,
  MutationRefreshUserPinArgs,
  MutationRemoveUserRolesArgs,
  MutationRemoveUserScopesArgs,
  MutationRemoveUserStoreRolesArgs,
  MutationUpdateUserArgs,
  QueryIamUserArgs,
  QueryUserArgs,
  QueryUsersArgs,
  QueryUserScopesArgs,
} from '@app/typings/user';
import { ApiService } from '@core/service/api.service';
import { Mutation, MutationResult, Query, QueryResult } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(private api: ApiService) {}

  users(variables: QueryUsersArgs): QueryResult<'users'> {
    return this.api.query<Query<'users'>, QueryUsersArgs>({
      query: queryUsers,
      variables,
    });
  }

  user(variables: QueryUserArgs): QueryResult<'user'> {
    return this.api.query<Query<'user'>, QueryUserArgs>({
      query: queryUser,
      variables,
    });
  }

  iamUser(variables: QueryIamUserArgs): QueryResult<'iamUser'> {
    return this.api.query<Query<'iamUser'>, QueryIamUserArgs>({
      query: queryIamUser,
      variables,
    });
  }

  inviteUser(variables: MutationInviteUserArgs): MutationResult<'inviteUser'> {
    return this.api.mutate<Mutation<'inviteUser'>, MutationInviteUserArgs>({
      mutation: mutationInviteUser,
      variables,
    });
  }

  updateUser(variables: MutationUpdateUserArgs): MutationResult<'updateUser'> {
    return this.api.mutate<Mutation<'updateUser'>, MutationUpdateUserArgs>({
      mutation: mutationUpdateUser,
      variables,
    });
  }

  deleteUser(variables: MutationDeleteUserArgs): MutationResult<'deleteUser'> {
    return this.api.mutate<Mutation<'deleteUser'>, MutationDeleteUserArgs>({
      mutation: mutationDeleteUser,
      variables,
    });
  }

  addUserRoles(variables: MutationAddUserRolesArgs): MutationResult<'addUserRoles'> {
    return this.api.mutate<Mutation<'addUserRoles'>, MutationAddUserRolesArgs>({
      mutation: mutationAddUserRoles,
      variables,
    });
  }

  removeUserRoles(variables: MutationRemoveUserRolesArgs): MutationResult<'removeUserRoles'> {
    return this.api.mutate<Mutation<'removeUserRoles'>, MutationRemoveUserRolesArgs>({
      mutation: mutationRemoveUserRoles,
      variables,
    });
  }

  addUserStoreRoles(variables: MutationAddUserStoreRolesArgs): MutationResult<'addUserStoreRoles'> {
    return this.api.mutate<Mutation<'addUserStoreRoles'>, MutationAddUserStoreRolesArgs>({
      mutation: mutationAddUserStoreRoles,
      variables,
    });
  }

  removeUserStoreRoles(variables: MutationRemoveUserStoreRolesArgs): MutationResult<'removeUserStoreRoles'> {
    return this.api.mutate<Mutation<'removeUserStoreRoles'>, MutationRemoveUserStoreRolesArgs>({
      mutation: mutationRemoveUserStoreRoles,
      variables,
    });
  }

  changeUserPin(variables: MutationRefreshUserPinArgs): MutationResult<'refreshUserPin'> {
    return this.api.mutate<Mutation<'refreshUserPin'>, MutationRefreshUserPinArgs>({
      mutation: mutationChangeUserPin,
      variables,
    });
  }

  userScopes(variables: QueryUserScopesArgs): QueryResult<'userScopes'> {
    return this.api.query<Query<'userScopes'>, QueryUserScopesArgs>({
      query: queryUserScopes,
      variables,
    });
  }

  addUserScopes(variables: MutationAddUserScopesArgs): MutationResult<'addUserScopes'> {
    return this.api.mutate<Mutation<'addUserScopes'>, MutationAddUserScopesArgs>({
      mutation: mutationAddUserScopes,
      variables,
    });
  }

  removeUserScopes(variables: MutationRemoveUserScopesArgs): MutationResult<'removeUserScopes'> {
    return this.api.mutate<Mutation<'removeUserScopes'>, MutationRemoveUserScopesArgs>({
      mutation: mutationRemoveUserScopes,
      variables,
    });
  }
}
