import { gql } from 'apollo-angular';

import { fragmentRequestResult } from './mutations';

export const fragmentStorePage = gql`
  fragment StorePage on StorePage {
    content {
      id
      name
    }
  }
`;

export const fragmentWarehouse = gql`
  fragment Warehouse on Warehouse {
    id
    name
    addressName
    storesCount
    archived
    archivedDate
    timezoneVerified
    zoneId
    stores {
      ...StorePage
    }
  }
  ${fragmentStorePage}
`;

export const queryAllWarehousesPageable = gql`
  query allWarehousesPageable($pageRequest: PageRequestInput!, $filter: WarehouseFilterInput, $sort: [WarehousesPageableSort!]) {
    allWarehousesPageable(pageRequest: $pageRequest, filter: $filter, sort: $sort) {
      content {
        ...Warehouse
      }
      pageSize
      pageNumber
      totalPages
      total
    }
  }
  ${fragmentWarehouse}
`;

export const queryAllWarehousesPageableShort = gql`
  query allWarehousesPageableShort($pageRequest: PageRequestInput!, $filter: WarehouseFilterInput) {
    allWarehousesPageable(pageRequest: $pageRequest, filter: $filter) {
      content {
        id
        name
      }
      pageSize
      pageNumber
      totalPages
      total
    }
  }
`;

export const queryWarehousesTotal = gql`
  query warehousesTotal($pageRequest: PageRequestInput!, $filter: WarehouseFilterInput) {
    allWarehousesPageable(pageRequest: $pageRequest, filter: $filter) {
      total
    }
  }
`;

export const queryAllWarehouseItemsPageable = gql`
  query allWarehouseItemsPageable($filter: WarehouseItemFilterInput!, $pageRequest: PageRequestInput!) {
    allWarehouseItemsPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        name
        stockUnit {
          id
          name
          unit
          quantity
          primePrice {
            amountValue
            currencyUnit
          }
          salePrice {
            amountValue
            currencyUnit
          }
        }
      }
    }
  }
`;

export const queryWarehouseStockUnitsIds = gql`
  query warehouseStockUnitsIds($warehouseId: UUID!) {
    warehouseStockUnitsIds(warehouseId: $warehouseId)
  }
`;

export const queryGetWarehouseItemsWrittenOffReportByWarehouse = gql`
  query getWarehouseItemsWrittenOffReportByWarehouse(
    $page: Int!
    $size: Int!
    $sortOrder: String
    $search: String
    $dateFrom: LocalDate
    $dateTo: LocalDate
    $warehouseId: UUID!
  ) {
    getWarehouseItemsWrittenOffReportByWarehouse(
      page: $page
      size: $size
      sortOrder: $sortOrder
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      warehouseId: $warehouseId
    ) {
      stockUnitName
      warehouseName
      totalQuantity
      averagePrice
      currencyUnit
      lastArrivalQuantity
      unitType
    }
  }
`;

export const queryCountWarehouseItemsWrittenOffReportByWarehouse = gql`
  query countWarehouseItemsWrittenOffReportByWarehouse($dateFrom: LocalDate!, $dateTo: LocalDate!, $search: String, $warehouseId: UUID!) {
    countWarehouseItemsWrittenOffReportByWarehouse(dateFrom: $dateFrom, dateTo: $dateTo, search: $search, warehouseId: $warehouseId)
  }
`;

export const queryWarehouseItemsWrittenOffReportsExist = gql`
  query warehouseItemsWrittenOffReportsExist {
    warehouseItemsWrittenOffReportsExist
  }
`;

export const queryWarehouseItemLeftovers = gql`
  query warehouseItemLeftovers(
    $filter: WarehouseItemLeftoverFilterInput!
    $pageRequest: PageRequestInput!
    $sort: [WarehouseItemLeftoverSort!]
  ) {
    warehouseItemLeftovers(filter: $filter, pageRequest: $pageRequest, sort: $sort) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        productId
        product {
          id
          stockUnits {
            id
            name
            images {
              id
              originalFileName
              extension
              imageSizes {
                width
                height
                url
              }
            }
            quantity
            unit
            unitTypesMatchList
            primePrice {
              amountValue
              currencyUnit
            }
          }
        }
        name
        sectionName
        quantity
        unitType
        primePrice {
          amountValue
          currencyUnit
        }
        lastArrivalDate
        lastArrivalDocument {
          creationDate
          date
          description
          docNumber
          id
          status
          typeDocument
        }
        warehouseName
        type
        stores {
          name
        }
      }
    }
  }
`;

export const queryLeftovers = gql`
  query leftovers($input: LeftoversInput, $page: PageableInput!, $sort: [LeftoverSort!]) {
    leftovers(input: $input) {
      total {
        sum
      }
      rows(page: $page, sort: $sort) {
        total
        pageNumber
        pageSize
        totalPages
        content {
          productId
          catalogProductId
          categoryName
          lastArrivalDate
          lastArrivalDocument {
            id
            number
          }
          name
          productType
          primePrice
          quantity
          storageRoomName
          stores {
            id
            name
          }
          totalCost
          unitType
        }
      }
    }
  }
`;

export const queryWarehouseFull = gql`
  query warehouse($id: UUID!) {
    warehouse(id: $id) {
      id
      name
      addressName
      description
      phone
      shippingCenter
      pickupLocation
      timezoneVerified
      active
      zoneId
      stores {
        ...StorePage
      }
    }
  }
  ${fragmentStorePage}
`;

export const mutationCreateWarehouseV2 = gql`
  mutation createWarehouseV2($input: WarehouseCreateInputV2!) {
    createWarehouseV2(input: $input) {
      ...RequestResult
      output {
        id
        name
      }
    }
  }
  ${fragmentRequestResult}
`;

export const mutationUpdateWarehouseV2 = gql`
  mutation updateWarehouseV2($input: WarehouseUpdateInputV2!) {
    updateWarehouseV2(input: $input) {
      ...RequestResult
      output {
        id
        name
        addressName
        storesCount
        archived
        archivedDate
        timezoneVerified
        zoneId
        stores {
          ...StorePage
        }
      }
    }
  }
  ${fragmentRequestResult}
  ${fragmentStorePage}
`;

export const queryStorageRoom = gql`
  query storageRoom($id: UUID!) {
    storageRoom(id: $id) {
      id
      name
      address
      archived
      archivedDate
      description
      phone
      zoneId
      stores {
        ...StorePage
      }
    }
  }
  ${fragmentStorePage}
`;

export const queryStorageRooms = gql`
  query storageRooms($filter: StorageRoomFilterInput, $pageRequest: PageRequestInput!) {
    storageRooms(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        name
        address
        archivedDate
        description
        phone
        zoneId
        stores {
          ...StorePage
        }
      }
      pageSize
      pageNumber
      totalPages
      total
    }
  }
  ${fragmentStorePage}
`;

export const mutationCreateStorageRoom = gql`
  mutation createStorageRoom($createInput: CreateStorageRoomInput!) {
    createStorageRoom(createInput: $createInput) {
      result {
        ...RequestResult
        output {
          id
          name
          address
          archivedDate
          zoneId
          stores {
            ...StorePage
          }
        }
      }
    }
  }
  ${fragmentRequestResult}
  ${fragmentStorePage}
`;

export const mutationUpdateStorageRoom = gql`
  mutation updateStorageRoom($id: UUID!, $updateInput: UpdateStorageRoomInput!) {
    updateStorageRoom(id: $id, updateInput: $updateInput) {
      result {
        ...RequestResult
        output {
          id
          name
          address
          archivedDate
          zoneId
          stores {
            ...StorePage
          }
        }
      }
    }
  }
  ${fragmentRequestResult}
  ${fragmentStorePage}
`;

export const mutationArchiveStorageRoom = gql`
  mutation archiveStorageRoom($archiveInput: ArchiveStorageRoomInput!) {
    archiveStorageRoom(archiveInput: $archiveInput) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationUnarchiveStorageRoom = gql`
  mutation unarchiveStorageRoom($unarchiveInput: UnarchiveStorageRoomInput!) {
    unarchiveStorageRoom(unarchiveInput: $unarchiveInput) {
      status {
        errorMessage
        result
      }
    }
  }
`;
