import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';
import { CompareCellInfo } from '@utils';

@Component({
  selector: 'nm-widget-comparison',
  templateUrl: './widget-comparison.component.html',
  styleUrls: ['./widget-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetComparisonComponent implements OnChanges {
  @Input() firstValue: string;
  @Input() secondValue: string;
  @Input() percentV2: boolean;

  compareCellInfo: CompareCellInfo = {
    percent: '0',
    color: this.getDefaultColor(),
    icon: null,
  };
  constructor(private cdRef: ChangeDetectorRef) {}
  ngOnChanges(): void {
    const firstValue = Number(String(this.firstValue).replace(/[^0-9.-]/g, ''));
    const secondValue = Number(String(this.secondValue).replace(/[^0-9.-]/g, ''));

    this.compareCellInfo = this.compareInfoPercentage(firstValue, secondValue);
    this.cdRef.detectChanges();
  }

  compareInfoPercentage = (a: number, b: number): CompareCellInfo => {
    let color = this.getDefaultColor();
    let icon = null;
    let percent = this.percentV2 ? this.getPercentV2(a, b) : this.getPercent(a, b);
    if (b === 0 || a === 0) {
      if (b < a) {
        return {
          percent: '100%',
          color: '#006D44',
          icon: null,
        };
      } else if (b > a) {
        return {
          percent: '-100%',
          color: '#BA1A1A',
          icon: null,
        };
      } else {
        return {
          percent: '0%',
          color: this.getDefaultColor(),
          icon: null,
        };
      }
    }
    if (!isFinite(percent) || isNaN(percent)) {
      return {
        percent: '',
        icon: null,
        color,
      };
    }

    let returnString = `${percent.toFixed(2).toString()}%`;

    if (percent < -4.99) {
      color = '#BA1A1A';
      if (!this.percentV2) {
        icon = 'arrowDown' as SVG_ICONS_TYPE;
        color = '#BA1A1A';
      }
      returnString = `${percent.toFixed(2).toString()}%`;
    } else if (percent > 4.99) {
      color = '#006D44';
      if (!this.percentV2) {
        icon = 'arrowUp' as SVG_ICONS_TYPE;
      }
      returnString = `${percent.toFixed(2).toString()}%`;
    }

    return {
      percent: returnString,
      icon,
      color,
    };
  };

  getDefaultColor() {
    return (localStorage.getItem('prefers-color') === 'system' && localStorage.getItem('system-color') === 'dark') ||
      localStorage.getItem('prefers-color') === 'dark'
      ? '#e3e2e6'
      : '#1A1B1F';
  }

  getPercent(a: number, b: number) {
    return ((a - b) / Math.abs(a)) * 100;
  }

  getPercentV2(a: number, b: number) {
    return (a / b) * 100;
  }
}
