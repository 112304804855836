import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';

type SectionContentStyles = { paddingTop: string; paddingBottom: string; height: string };

@Component({
  selector: 'nm-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  constructor(private el: ElementRef) {}
  @Input() isHeader: boolean = false;
  @Input() isFooter: boolean = false;

  get setStyles(): SectionContentStyles {
    let styles: SectionContentStyles = {
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '100%',
    };

    if (this.isHeader) {
      styles = { ...styles, paddingTop: '24px' };
    }

    if (this.isFooter) {
      styles = { ...styles, paddingBottom: '64px', height: 'auto' };
    }

    return styles;
  }
}
