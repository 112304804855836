import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { SharedPipesModule } from '@app/shared/pipe/pipes.module';

import { WidgetComparisonComponent } from './widget-comparison.component';

@NgModule({
  declarations: [WidgetComparisonComponent],
  imports: [CommonModule, SvgIconModule, SharedPipesModule],
  exports: [WidgetComparisonComponent],
})
export class WidgetComparisonModule {}
