import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UtilsService } from '@core/service/utils.service';
import { OrganizationSearchDto } from '@typings';

@Component({
  selector: 'nm-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent {
  public isHover = false;

  @Input() organizations: OrganizationSearchDto[];

  @Output() setOrganization = new EventEmitter();

  constructor(private utilsService: UtilsService) {
    this.isHover = this.utilsService.isTouch();
  }

  public trackByFunction(index: number): number {
    return index;
  }

  public onSetOrganization(organization: OrganizationSearchDto): void {
    this.setOrganization.emit(organization);
  }
}
