import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryShiftNumbers = gql`
  query shiftNumbers($storeIds: [UUID!], $pageRequest: PageRequestInput) {
    shiftNumbers(storeIds: $storeIds, pageRequest: $pageRequest) {
      content
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryShifts = gql`
  query shifts($filter: ShiftsFilterInput, $pageRequest: PageRequestInput) {
    shifts(filter: $filter, pageRequest: $pageRequest) {
      content {
        accountingBalance
        checksCount
        closeFactBalance
        dateClose
        dateOpen
        dateOpenLocal
        dateCloseLocal
        shiftNumber
        storeName
        id
        deltaBalance
        openFactBalance
      }
      total
      totalPages
      pageNumber
      pageSize
    }
  }
`;

export const queryShiftEvents = gql`
  query shiftEvents($filter: ShiftEventsFilterInput!, $pageRequest: PageRequestInput) {
    shiftEvents(filter: $filter, pageRequest: $pageRequest) {
      content {
        ... on ShiftClosed {
          operator {
            firstName
            lastName
            roles
            id
          }
          comment
          accountingBalance
          date
          dateLocal
          eventType
          factBalance
        }
        ... on ShiftExpired {
          date
          dateLocal
          eventType
          id
          shiftId
        }
        ... on ShiftOpened {
          operator {
            firstName
            lastName
            roles
            id
          }
          comment
          accountingBalance
          date
          dateLocal
          eventType
          factBalance
          deviceId
        }

        ... on ShiftOrder {
          operator {
            firstName
            lastName
            roles
            id
          }
          amount
          accountingBalance
          date
          dateLocal
          eventType
        }
        ... on ShiftTransaction {
          operator {
            firstName
            lastName
            roles
            id
          }
          amount
          comment
          accountingBalance
          date
          dateLocal
          eventType
        }
      }
      total
      totalPages
      pageSize
    }
  }
`;
export const queryShift = gql`
  query shift($id: UUID!) {
    shift(id: $id) {
      accountingBalance
      checksCount
      closeFactBalance
      dateClose
      dateOpen
      dateOpenLocal
      dateCloseLocal
      shiftNumber
      storeName
      id
      deltaBalance
      openFactBalance
      nonCashRevenue
      cashRevenue
    }
  }
`;
