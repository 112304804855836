import { Injectable, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserService } from '@app/modules/employees/services/employees/user.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { OpenAccessComponent } from '@app/shared/component/open-access/open-access.component';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { AccessFrom, UserAccessRow } from '@app/typings/access';
import { AddUserScopesInput } from '@app/typings/user';
import { ACCESS_TYPE, ACCESS_TYPE_STRING, AccessRoute } from '@constants/access';
import { BreakpointObserverService, ValidationErrorsService } from '@core/service';
import { SessionStorage } from '@services/api';
import { StoresStorage } from '@services/settings';
import { FilterPanelService, NotifyService, RedirectService } from '@services/shared';
import { Store } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class OpenAccessService {
  form: FormGroup<AccessFrom>;
  scope$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isSubmitDisabled$ = new BehaviorSubject(false);

  rows$ = new BehaviorSubject<UserAccessRow[]>([]);

  allStores$ = new BehaviorSubject<Store[]>(this.storesStorage.allStores$.getValue());
  #allSelectedStores = new BehaviorSubject(true);
  allSelectedStores$ = this.#allSelectedStores.asObservable();

  setAllSelectedStores(selected: boolean): void {
    this.#allSelectedStores.next(selected);
  }

  getAllSelectedStores(): boolean {
    return this.#allSelectedStores.getValue();
  }

  constructor(
    private fb: FormBuilder,
    private storesStorage: StoresStorage,
    public bos: BreakpointObserverService,
    private redirectService: RedirectService,
    private sessionStorage: SessionStorage,
    private notifyService: NotifyService,
    private userService: UserService,
    public filterPanelService: FilterPanelService,
    private tableSidebarService: TableSidebarLayoutService,
    private validationErrorsService: ValidationErrorsService,
  ) {
    this.storesStorage.allStores$.subscribe((res) => this.allStores$.next(res));
  }

  openAccessPage(scope: string) {
    this.scope$.next(scope);
    this.bos.isMaxWidth900$.pipe(take(1)).subscribe((res: boolean) => {
      if (res) {
        this.redirectService.redirectToOpenAccess(this.sessionStorage.getOrgId());
      } else {
        this.tableSidebarService.setLoadingState();
        this.tableSidebarService.openTableSidenav({
          component: OpenAccessComponent,
          sidebarComponentData: scope,
          title: 'Открыть доступ',
          idForRedirect: AccessRoute,
        });
      }
    });
  }

  initForm() {
    this.form = this.fb.group<AccessFrom>({
      user: this.fb.control(null, [Validators.required]),
      stores: this.fb.nonNullable.control([]),
      access: this.fb.nonNullable.control(ACCESS_TYPE.READER),
    });
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    if (!this.getAllSelectedStores() && !this.form.controls.stores.value.length) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо выбрать хотя бы одно заведение',
      });

      return;
    }

    if (this.rows$.getValue().find((row) => row.user.id === this.form.controls.user.value?.id)) {
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Пользователь уже добавлен',
      });

      return;
    }

    this.#disableForm();
    this.addUserScope();
  }

  addUserScope(): void {
    const input = this.getUserScopeInput();
    this.userService.addUserScopes(input).subscribe(
      (res) => {
        const { user, stores, access } = this.form.controls;
        const userValue = user.value!.data!;
        const selStores = this.getAllSelectedStores() ? this.allStores$.getValue() : stores.value.map((s) => s.data!);
        const item: UserAccessRow = {
          id: res.userScopeIds[0],
          user: user.value!.data!,
          userName: `${userValue.lastName || ''} ${userValue.firstName || ''} ${userValue.middleName || ''}`,
          stores: selStores,
          storesControl: this.fb.nonNullable.control(this.getAutocompleteStores(selStores)),
          allStores: new BehaviorSubject(this.getAllSelectedStores()),
          access: ACCESS_TYPE_STRING[access.value],
          tools: this.getMenuList(),
        };
        this.rows$.next([...this.rows$.getValue(), item]);
        this.#enableForm();
        this.initForm();
      },
      () => this.#enableForm(),
    );
  }

  removeUserScope(id: string) {
    this.userService.removeUserScopes({ userScopeIds: [id] }).subscribe(() => {
      this.filterPanelService.closePanel();
      const rows = this.rows$.getValue();
      const removeRow = rows.find((r) => r.id === id);
      if (removeRow) {
        const removeIndex = rows.indexOf(removeRow);
        rows.splice(removeIndex, 1);
        this.rows$.next(rows);
      }
    });
  }

  updateUserAccess(item: UserAccessRow, selectedStores: AutocompleteOption<Store>[]) {
    const { id, storesControl, user } = item;
    const isAllStores = selectedStores.length === this.allStores$.getValue().length;
    this.userService.removeUserScopes({ userScopeIds: [id] }).subscribe(() => {
      const input: AddUserScopesInput = {
        userId: user.id,
        scopes: [
          {
            scope: this.scope$.getValue(),
            storeIds: isAllStores ? this.allStores$.getValue().map((s) => s.id) : selectedStores.map((s) => s.id),
            allStores: isAllStores,
          },
        ],
      };

      this.userService.addUserScopes(input).subscribe((res) => {
        storesControl.setValue(selectedStores);
        item.id = res.userScopeIds[0];
      });
    });
  }

  getUserScopeInput(): AddUserScopesInput {
    const { user, stores } = this.form.controls;
    const selectedStores = stores.value.map((s) => s.id);
    return {
      userId: user.value?.id || '',
      scopes: [
        {
          scope: this.scope$.getValue(),
          storeIds: this.getAllSelectedStores() ? this.allStores$.getValue().map((s) => s.id) : selectedStores,
          allStores: this.getAllSelectedStores(),
        },
      ],
    };
  }

  #enableForm(): void {
    this.form.enable({ emitEvent: false });
    this.isSubmitDisabled$.next(false);
  }

  #disableForm(): void {
    this.form.disable({ emitEvent: false });
    this.isSubmitDisabled$.next(true);
  }

  getMenuList(): MenuItem[] {
    return [
      {
        id: ACCESS_TYPE.READER,
        type: 'item',
        label: ACCESS_TYPE_STRING.READER,
        iconRight: 'check',
        iconRightColor: '#8e9099',
      },
      {
        type: 'divider',
        label: 'divider',
      },
      {
        id: 'remove',
        type: 'item',
        label: 'Закрыть доступ',
        iconRight: 'close',
        iconRightColor: '#8e9099',
      },
    ];
  }

  setTemplate(template: TemplateRef<unknown>) {
    this.filterPanelService.setView(template);
  }

  getAutocompleteStores(stores: Store[]): AutocompleteOption<Store>[] {
    return stores.map((s) => ({
      label: s.name,
      type: 'item',
      id: s.id,
      data: s,
    }));
  }
}
