<nm-form *ngIf="accessService.form as form" [form]="form">
  <nm-form-group title="Добавить пользователя" [isAction]="false" [isOpen]="true">
    <nm-autocomplete
      #userAutoComplete
      ngDefaultControl
      placeholder="Введите почту или имя"
      [search]="true"
      [returnObjects]="true"
      [formControl]="user"
      [searchFn]="searchUserService.searchFn"
      [options]="searchUserService.options$"
      [loadingState]="searchUserService.loadingState$"
      (loadMore)="searchUserService.loadItems()"
      [error]="validationErrorsService.isControlDirtyAndInvalid(user)"
      [hint]="validationErrorsService.validateField(user) || user.getError('user')"
      (menuOpen)="onOpenUserAutocomplete(user, searchUserService, userAutoComplete.searchText)"
    ></nm-autocomplete>

    <div class="access-form">
      <nm-autocomplete
        ngDefaultControl
        placeholder="Выберите точки продаж"
        [isMulti]="true"
        [returnObjects]="true"
        [search]="true"
        [formControl]="stores"
        [showClear]="false"
        [chipCount]="true"
        [textCount]="'точки продаж'"
        [width]="210"
        [searchFn]="searchStoresService.searchFn"
        [options]="searchStoresService.options$"
        [allSelected]="accessService.allSelectedStores$ | async"
        [selectAllSettings]="{ title: 'Все точки продаж' }"
        [error]="validationErrorsService.isControlDirtyAndInvalid(stores)"
        (selectAllHandler)="accessService.setAllSelectedStores($event.selected)"
      ></nm-autocomplete>

      <nm-autocomplete
        ngDefaultControl
        placeholder="Выберите доступ"
        [options]="accessList$"
        [showClear]="false"
        [formControl]="access"
      ></nm-autocomplete>

      <nm-button (clickButton)="addUserAccess()" [loading]="accessService.isSubmitDisabled$ | async">
        Поделиться
      </nm-button>
    </div>
  </nm-form-group>

  <nm-form-group title="Пользователи, имеющие доступ" [isAction]="false" [isOpen]="true">
    <ng-container *ngFor="let row of accessService.rows$ | async">
      <div class="users-access">
        <nm-table-title [name]="row.userName" [description]="row.user.workEmail"></nm-table-title>
        <div class="users-access-dropdown">
          <nm-autocomplete
            ngDefaultControl
            placeholder="Выберите точки продаж"
            [isMulti]="true"
            [returnObjects]="true"
            [search]="true"
            [formControl]="row.storesControl"
            [showClear]="false"
            [chipCount]="true"
            [buttonBlock]="true"
            [width]="180"
            [textCount]="'точки продаж'"
            [searchFn]="searchStoresService.searchFn"
            [options]="searchStoresService.options$"
            [allSelected]="row.allStores | async"
            [disableModelUpdatesFromMenu]="true"
            [okButtonLabel]="'Применить'"
            [selectAllSettings]="{ title: 'Все точки продаж' }"
            (selectAllHandler)="row.allStores.next($event.selected)"
            (okClick)="onOkClick($event, row)"
          ></nm-autocomplete>
          <nm-dropdown-menu
            #dropdownMenuComponent
            (click)="accessService.setTemplate(accessTemplate)"
            [initView]="accessTemplate"
          >
            <div class="user-dropdown">
              {{ row.access }}
              <nm-svg-icon name="arrowDropDown" [size]="18"></nm-svg-icon>
            </div>
          </nm-dropdown-menu>
        </div>

        <ng-template #accessTemplate>
          <nm-menu-content
            [maxWidth]="260"
            [maxItemsHeight]="360"
            [items]="row.tools"
            [selectedItems]="getSet(row.access)"
            (itemClick)="selectToolItem($event, row.id, dropdownMenuComponent)"
            [closeAfterItemClick]="true"
          ></nm-menu-content>
        </ng-template>
      </div>
    </ng-container>
  </nm-form-group>
</nm-form>
