<section class="section">
  <nm-section-header *ngIf="isHeader">
    <ng-content select="[header]"></ng-content>
  </nm-section-header>

  <nm-section-content>
    <div class="wrapper" [ngStyle]="setStyles">
      <ng-content select="[main]"></ng-content>
    </div>
  </nm-section-content>

  <nm-section-footer *ngIf="isFooter">
    <ng-content select="[footer]"></ng-content>
  </nm-section-footer>
</section>
