<mat-tab-group
  #matTabGroup
  class="tab-group"
  [ngClass]="tabGroupClasses"
  [selectedIndex]="selectedIndex"
  animationDuration="0ms"
  [disableRipple]="true"
  [disablePagination]="disablePagination"
  mat-stretch-tabs
  [attr.data-test-id]="testId"
  (selectedIndexChange)="onSelectedIndexChange($event)"
  (touchmove)="onScroll($event)"
>
  <mat-tab *ngFor="let tab of tabs; let idx = index" [disabled]="tab.disabled">
    <ng-template mat-tab-label>
      <div
        *ngIf="!tab.metric"
        #tabLabel
        [ngClass]="tabLabelClasses"
        [attr.data-test-id]="'tab-' + (idx + 1)"
        (mouseup)="blur(tabLabel)"
        (mouseleave)="blur(tabLabel)"
      >
        <nm-svg-icon *ngIf="tab.icon" [size]="24" [name]="tab.icon" class="icon"></nm-svg-icon>
        <span *ngIf="tab.label" class="label">{{ tab.label }}</span>
      </div>

      <div #tabMetric>
        <nm-metric
          *ngIf="tab.metric as metric"
          [title]="getOrDefault(metric, 'title')"
          [value]="getOrDefault(metric, 'value')"
          [secondaryValue]="getOrDefault(metric, 'secondaryValue')"
          [secondaryValueIcon]="getOrDefault(metric, 'secondaryValueIcon')"
          [secondaryValueColor]="getOrDefault(metric, 'secondaryValueColor')"
          [firstCompareValue]="getOrDefault(metric, 'firstCompareValue')"
          [secondCompareValue]="getOrDefault(metric, 'secondCompareValue')"
          [description]="getOrDefault(metric, 'description')"
          [helpText]="getOrDefault(metric, 'helpText')"
          [testId]="getOrDefault(metric, 'testId')"
          [isLoading]="isLoading"
          (mouseup)="blur(tabMetric)"
          (mouseleave)="blur(tabMetric)"
        ></nm-metric>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="tab.contentTemplate"></ng-container>
  </mat-tab>
</mat-tab-group>
