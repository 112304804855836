import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { MetricModule } from '@app/shared/component/metric/metric.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';

import { TabGroupComponent } from './tab-group.component';

@NgModule({
  declarations: [TabGroupComponent],
  imports: [CommonModule, MatTabsModule, SvgIconModule, MetricModule],
  exports: [TabGroupComponent],
})
export class TabGroupModule {}
