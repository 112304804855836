import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { WidgetItem } from '@app/shared/component/widget-layout/widget-item.directive';
import { SalesByWeekDayWidgetMetric } from '@app/typings/weekday-widget';
import {
  OrderTypeWidgetMetric,
  OrderTypeWidgetMetricPercent,
  PaymentSystemWidgetMetric,
  PaymentSystemWidgetMetricPercent,
  SalesByHourWidgetMetric,
  SalesTodayMetricKey,
  SalesWidgetGraphMetric,
  StrictDictionary,
} from '@typings';

export type DashboardWidgetName =
  | 'weekdayWidget'
  | 'paySystemsWidget'
  | 'todayWidget'
  | 'byHourWidget'
  | 'orderTypeWidget'
  | 'popularProducts'
  | 'popularSections'
  | 'salesWidget';

export const DASHBOARD_WIDGETS_NAMES: Record<DashboardWidgetName, DashboardWidgetName> = {
  todayWidget: 'todayWidget',
  salesWidget: 'salesWidget',
  weekdayWidget: 'weekdayWidget',
  byHourWidget: 'byHourWidget',
  orderTypeWidget: 'orderTypeWidget',
  popularProducts: 'popularProducts',
  popularSections: 'popularSections',
  paySystemsWidget: 'paySystemsWidget',
};

export const DASHBOARD_WEEKDAY_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.weekdayWidget,
  desktop: {
    numberOfColumns: 6,
    startColumn: 7,
  },
  tableLandscape: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_BY_HOUR_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.byHourWidget,
  desktop: {
    numberOfColumns: 6,
    startColumn: 1,
  },
  tableLandscape: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_PAY_SYSTEM_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.paySystemsWidget,
  desktop: {
    numberOfColumns: 6,
    startColumn: 1,
  },
  tableLandscape: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_TODAY_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.todayWidget,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_POPULAR_PRODUCTS_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.popularProducts,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_POPULAR_SECTIONS_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.popularSections,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_ORDER_TYPE_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.orderTypeWidget,
  desktop: {
    numberOfColumns: 6,
    startColumn: 7,
  },
  tableLandscape: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_SALES_WIDGET: WidgetItem = {
  name: DASHBOARD_WIDGETS_NAMES.salesWidget,
  desktop: {
    numberOfColumns: 12,
    startColumn: 1,
  },
};

export const DASHBOARD_WIDGETS_CONFIG: WidgetItem[] = [
  DASHBOARD_TODAY_WIDGET,
  DASHBOARD_SALES_WIDGET,
  DASHBOARD_BY_HOUR_WIDGET,
  DASHBOARD_WEEKDAY_WIDGET,
  DASHBOARD_ORDER_TYPE_WIDGET,
  DASHBOARD_POPULAR_PRODUCTS_WIDGET,
  DASHBOARD_POPULAR_SECTIONS_WIDGET,
  DASHBOARD_PAY_SYSTEM_WIDGET,
];

export const PAY_SYSTEM_METRIC_PERCENT: StrictDictionary<PaymentSystemWidgetMetricPercent, PaymentSystemWidgetMetric> = {
  revenue: 'revenuePercentOutOfTotal',
  totalChecks: 'totalChecksPercentOutOfTotal',
  totalGuests: 'totalGuestsPercentOutOfTotal',
};

export const PAY_SYSTEM_WIDGET_METRICS: StrictDictionary<PaymentSystemWidgetMetric, PaymentSystemWidgetMetric> = {
  revenue: 'revenue',
  totalChecks: 'totalChecks',
  totalGuests: 'totalGuests',
};

export const PAY_SYSTEM_WIDGET_METRICS_UNIT: StrictDictionary<boolean, PaymentSystemWidgetMetric> = {
  revenue: true,
  totalChecks: false,
  totalGuests: false,
};

export const PAY_SYSTEM_WIDGET_METRICS_NAMES: StrictDictionary<string, PaymentSystemWidgetMetric> = {
  revenue: 'Выручка',
  totalChecks: 'Заказы',
  totalGuests: 'Гости',
};

export const WEEKDAY_WIDGET_METRICS: StrictDictionary<SalesByWeekDayWidgetMetric, SalesByWeekDayWidgetMetric> = {
  averageWeeklyRevenue: 'averageWeeklyRevenue',
  averageWeeklyProfit: 'averageWeeklyProfit',
  totalWeeklyChecksCount: 'totalWeeklyChecksCount',
  totalWeeklyGuestsCount: 'totalWeeklyGuestsCount',
  averageWeeklyCheck: 'averageWeeklyCheck',
};

export const WEEKDAY_WIDGET_METRICS_NAMES: StrictDictionary<string, SalesByWeekDayWidgetMetric> = {
  averageWeeklyRevenue: 'Выручка',
  averageWeeklyProfit: 'Прибыль',
  totalWeeklyChecksCount: 'Заказы',
  totalWeeklyGuestsCount: 'Гости',
  averageWeeklyCheck: 'Средний чек',
};

export const WEEKDAY_WIDGET_METRICS_UNITS: StrictDictionary<boolean, SalesByWeekDayWidgetMetric> = {
  averageWeeklyRevenue: true,
  averageWeeklyProfit: true,
  totalWeeklyChecksCount: false,
  totalWeeklyGuestsCount: false,
  averageWeeklyCheck: true,
};

export const BY_HOUR_WIDGET_METRICS: StrictDictionary<SalesByHourWidgetMetric, SalesByHourWidgetMetric> = {
  averageHourlyRevenue: 'averageHourlyRevenue',
  averageHourlyProfit: 'averageHourlyProfit',
  totalHourlyChecksCount: 'totalHourlyChecksCount',
  totalHourlyGuestsCount: 'totalHourlyGuestsCount',
  averageHourlyCheck: 'averageHourlyCheck',
};

export const BY_HOUR_WIDGET_METRICS_NAMES: StrictDictionary<string, SalesByHourWidgetMetric> = {
  averageHourlyRevenue: 'Выручка',
  averageHourlyProfit: 'Прибыль',
  totalHourlyChecksCount: 'Заказы',
  totalHourlyGuestsCount: 'Гости',
  averageHourlyCheck: 'Средний чек',
};

export const SALES_WIDGET_GRAPH_METRICS_NAMES: StrictDictionary<string, SalesWidgetGraphMetric> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  averageCheck: 'Средний чек',
  guestsCount: 'Количество гостей',
  ordersCount: 'Количество заказов',
  averageDuration: 'Время обслуживания',
  averageItemsCount: 'Глубина чека',
  discount: 'Сумма скидок',
  foodCost: 'Себестоимость',
};

export const BY_HOUR_WIDGET_METRICS_UNITS: StrictDictionary<boolean, SalesByHourWidgetMetric> = {
  averageHourlyRevenue: true,
  averageHourlyProfit: true,
  totalHourlyChecksCount: false,
  totalHourlyGuestsCount: false,
  averageHourlyCheck: true,
};

export const ORDER_TYPE_WIDGET_METRICS_NAMES: StrictDictionary<string, OrderTypeWidgetMetric> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  totalChecks: 'Заказы',
  totalGuests: 'Гости',
  averageCheck: 'Средний чек',
};

export const ORDER_TYPE_METRIC_PERCENT: StrictDictionary<OrderTypeWidgetMetricPercent, OrderTypeWidgetMetric> = {
  averageCheck: 'averageCheckPercentOutOfTotal',
  profit: 'profitPercentOutOfTotal',
  revenue: 'revenuePercentOutOfTotal',
  totalChecks: 'totalChecksPercentOutOfTotal',
  totalGuests: 'totalGuestsPercentOutOfTotal',
};
export const ORDER_TYPE_WIDGET_METRICS_UNIT: StrictDictionary<boolean, OrderTypeWidgetMetric> = {
  revenue: true,
  profit: true,
  totalChecks: false,
  totalGuests: false,
  averageCheck: true,
};

export const getPaySystemWidgetMetricsItems = (): MenuItem[] => {
  return Object.keys(PAY_SYSTEM_WIDGET_METRICS_NAMES).map((metric) => ({
    id: metric,
    label: PAY_SYSTEM_WIDGET_METRICS_NAMES[metric],
    type: 'item',
  }));
};

export const getWeekdayWidgetMetricsItems = (): MenuItem[] => {
  return Object.keys(WEEKDAY_WIDGET_METRICS_NAMES).map((metric) => ({
    id: metric,
    label: WEEKDAY_WIDGET_METRICS_NAMES[metric],
    type: 'item',
  }));
};

export const getByHourWidgetMetricsItems = (): MenuItem[] => {
  return Object.keys(BY_HOUR_WIDGET_METRICS).map((metric) => ({
    id: metric,
    label: BY_HOUR_WIDGET_METRICS_NAMES[metric],
    type: 'item',
  }));
};

export const getOrderTypeWidgetMetricsItems = (): MenuItem[] => {
  return Object.keys(ORDER_TYPE_WIDGET_METRICS_NAMES).map((metric) => ({
    id: metric,
    label: ORDER_TYPE_WIDGET_METRICS_NAMES[metric],
    type: 'item',
  }));
};

export const TODAY_WIDGET_METRICS_CHART: StrictDictionary<string, SalesTodayMetricKey> = {
  revenue: 'REVENUE',
  profit: 'PROFIT',
  guestsCount: 'GUEST_COUNT',
  averageCheck: 'AVERAGE_CHECK',
  discount: 'DISCOUNT',
  foodCost: 'PRIME_PRICE',
  checksCount: 'ORDERS_COUNT',
  marginPercent: 'MARGIN_PERCENT',
  averageDuration: 'AVERAGE_DURATION',
  averageItemsCount: 'AVERAGE_ITEMS_COUNT',
  discountPercent: 'DISCOUNT_PERCENT',
  foodCostPercent: 'FOOD_COST_PERCENT',
};

export const TODAY_WIDGET_METRICS: StrictDictionary<string, SalesTodayMetricKey> = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  guestsCount: 'Гости',
  averageCheck: 'Средний чек',
  discount: 'Сумма скидок',
  foodCost: 'Себестоимость',
  checksCount: 'Заказы',
  marginPercent: 'Маржинальность',
  averageDuration: 'Время обслуживания',
  averageItemsCount: 'Глубина чека',
  discountPercent: 'Доля скидок',
  foodCostPercent: 'Фудкост',
};

export const TODAY_WIDGET_METRICS_UNITS: StrictDictionary<boolean, SalesTodayMetricKey> = {
  revenue: true,
  profit: true,
  checksCount: false,
  guestsCount: false,
  averageCheck: true,
  averageDuration: false,
  averageItemsCount: false,
  discount: true,
  discountPercent: false,
  foodCost: true,
  foodCostPercent: false,
  marginPercent: false,
};
