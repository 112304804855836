import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '@app/modules/employees/services/employees/user.service';
import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { DropdownMenuComponent } from '@app/shared/component/dropdown-menu/dropdown-menu.component';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import {
  InfiniteLoaderAutocompleteFactory,
  InfiniteLoaderAutocompleteService,
} from '@app/shared/service/autocomplete/infinite-loader-autocomplete.service';
import { SearchAutocompleteService, SearchAutocompleteServiceFactory } from '@app/shared/service/autocomplete/search-autocomplete.service';
import { InfiniteLoadResult } from '@app/shared/service/infinite-loader/infinite-loader.service';
import { OpenAccessService } from '@app/shared/service/open-access/open-access.service';
import { UserAccessRow } from '@app/typings/access';
import { User } from '@app/typings/user';
import { ACCESS_TYPE_LIST, ACCESS_TYPE_STRING } from '@constants/access';
import { ValidationErrorsService } from '@core/service';
import { StoresService } from '@services/settings';
import { AnalyticsFiltersService } from '@services/shared';
import { PageRequestInput, Store } from '@typings';

@Component({
  selector: 'nm-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUsersComponent implements OnInit {
  positions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
  ];

  accessList$ = of(ACCESS_TYPE_LIST);
  searchUserService: InfiniteLoaderAutocompleteService<User>;
  searchStoresService: SearchAutocompleteService<Store>;

  get user() {
    return this.accessService.form.controls.user;
  }
  get access() {
    return this.accessService.form.controls.access;
  }

  get stores() {
    return this.accessService.form.controls.stores;
  }

  constructor(
    private fb: FormBuilder,
    public accessService: OpenAccessService,
    private userService: UserService,
    private storesService: StoresService,
    public analyticsFilterService: AnalyticsFiltersService,
    public validationErrorsService: ValidationErrorsService,
  ) {
    this.searchUserService = InfiniteLoaderAutocompleteFactory.getService(this.#searchUserFn);
    this.searchStoresService = SearchAutocompleteServiceFactory.getService(this.#searchStoresFn);
    this.userService
      .getUserScopes({ scopes: [this.accessService.scope$.getValue()], userIds: [] }, { page: 0, size: 100000 })
      .subscribe((res) => {
        const { content } = res;
        const rows: UserAccessRow[] = content.map((item) => ({
          id: item.userScopeId || '',
          user: item.user,
          userName: `${item.user.lastName || ''} ${item.user.firstName || ''} ${item.user.middleName || ''}`,
          stores: item.stores || [],
          storesControl: this.fb.nonNullable.control(this.accessService.getAutocompleteStores(item.stores || [])),
          allStores: new BehaviorSubject<boolean>(item.allStores),
          access: ACCESS_TYPE_STRING.READER,
          tools: this.accessService.getMenuList(),
        }));

        this.accessService.rows$.next(rows);
      });
  }

  ngOnInit(): void {
    this.searchStoresService.searchFn('');
  }

  addUserAccess() {
    this.accessService.submitForm();
  }

  #searchUserFn = (pageRequest: PageRequestInput, search?: string): Observable<InfiniteLoadResult<AutocompleteOption<User>>> => {
    return this.userService.getUsers({ search }, pageRequest).pipe(
      map((res) => {
        const items: AutocompleteOption<User>[] = res.content
          .filter((user) => !!user.workEmail)
          .map((user) => ({
            id: user.id,
            label: user.workEmail,
            description: this.accessService.rows$.getValue().some((row) => row.user.id === user.id)
              ? 'Пользователь уже добавлен'
              : `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`,
            disabled: this.accessService.rows$.getValue().some((row) => row.user.id === user.id) || user.scopes?.some((s) => s === 'owner'),
            type: 'item',
            data: user,
          }));

        return { items, total: res.total, totalPages: res.totalPages };
      }),
    );
  };

  onOpenUserAutocomplete(
    control: FormControl<AutocompleteOption<User> | null>,
    searchService: InfiniteLoaderAutocompleteService<User>,
    searchText: string,
  ): void {
    searchService.search(control.value ? '' : searchText, true);
  }

  #searchStoresFn = (searchText: string): Observable<AutocompleteOption<Store>[]> => {
    return this.storesService.getStoresForSearch(searchText || '').pipe(
      map((stores) =>
        stores.map((store) => ({
          label: store.name,
          type: 'item',
          id: store.id,
          data: store,
        })),
      ),
    );
  };

  getStores(stores: Store[]): string {
    if (stores.length === 1) {
      return stores[0].name;
    }

    if (stores.length === this.accessService.allStores$.getValue().length) {
      return 'Все точки продаж';
    }

    return stores.length + ' точки продаж';
  }

  getSet(access: string): Set<string> {
    return new Set(access);
  }

  onOkClick(value: Set<string>, row: UserAccessRow) {
    const selectedStores: AutocompleteOption<Store>[] = this.accessService.allStores$
      .getValue()
      .filter((s) => value.has(s.id))
      .map((store) => ({
        label: store.name,
        type: 'item',
        id: store.id,
        data: store,
      }));

    this.accessService.updateUserAccess(row, selectedStores);
  }

  selectToolItem(event: MenuItem, id: string, dropdown: DropdownMenuComponent) {
    if (event.id === 'remove') {
      this.accessService.removeUserScope(id);
    }
    dropdown.close();
  }
}
