<div
  class="nm-autocomplete"
  [ngClass]="{ 'autocomplete-disabled': disabled }"
  [style.width]="width + 'px'"
  (click)="focusInput()"
  *ngIf="optionsList$ | async | filter : optionsFilter as filteredOptions"
>
  <nm-control-label
    *ngIf="label || counterText"
    [label]="label"
    [required]="required"
    [showIcon]="showLabelIcon"
    [counterText]="counterText"
  ></nm-control-label>
  <nm-dropdown-menu
    [initView]="menuTmpl"
    [disabled]="disabled"
    (menuOpened)="onMenuOpened()"
    (menuClosed)="onMenuClosed()"
    #dropdownMenu
  >
    <nm-form-field
      *ngrxLet="{ selectedItems: selectedItems$ } as obs"
      #formField
      [ngClass]="{
        'dense-mode': isDenseMode || allSelectedCondition || collapseChipsLabel,
        'menu-opened': isMenuOpened
      }"
      [disabled]="disabled"
      [focused]="isFocused"
      [error]="error"
      [prefix]="prefix"
    >
      <div class="value-wrapper" [ngClass]="{ 'single-color-value': selectedHasPrefix }">
        <span *ngIf="valueColor" class="value-prefix value-color" [ngStyle]="{ 'background-color': valueColor }"></span>

        <nm-svg-icon
          class="value-prefix icon-wrapper-color"
          *ngIf="valueIcon"
          [name]="valueIcon"
          [size]="24"
        ></nm-svg-icon>

        <nm-avatar *ngIf="valueImage" [imageUrl]="valueImage" class="value-prefix" type="square"></nm-avatar>

        <ng-container *ngIf="isMulti && !allSelectedCondition && !collapseChipsLabel">
          <ng-container *ngIf="chipCount">
            <ng-container *ngIf="obs.selectedItems.length > 1">
              <div class="placeholder" [style.padding-left]="'8px'">
                {{ obs.selectedItems.length + ' ' + textCount }}
              </div>
            </ng-container>
            <ng-container *ngIf="obs.selectedItems.length === 1">
              <div class="placeholder" [style.padding-left]="'8px'">
                {{ displayValue(obs.selectedItems[0]) }}
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!chipCount">
            <nm-chip
              *ngFor="let chipItem of obs.selectedItems | limitArrayLength : limitChipsNumber"
              [iconRight]="dropdownMenu.isOpen || !showDeleteChip ? undefined : 'close'"
              [title]="displayValue(chipItem)"
              [disabled]="disabled"
              (rightIconClick)="removeItem(chipItem)"
            >
              {{ displayValue(chipItem) }}
            </nm-chip>
          </ng-container>
        </ng-container>

        <div *ngIf="showAllChip && chipCount" class="placeholder" [style.padding-left]="'8px'">
          {{ selectAllSettings.title }}
        </div>

        <nm-chip
          *ngIf="showAllChip && !chipCount"
          [title]="selectAllSettings.title"
          [disabled]="disabled"
          class="all-selected-chip"
        >
          {{ selectAllSettings.title }}
        </nm-chip>

        <div
          class="remain-chips"
          *ngIf="!showAllChip && limitChipsNumber && limitChipsNumber < obs.selectedItems.length"
        >
          +{{ obs.selectedItems.length - limitChipsNumber }}
        </div>

        <nm-chip
          *ngIf="isMulti && !allSelectedCondition && collapseChipsLabel"
          [disabled]="disabled"
          [ngStyle]="{ minWidth: 112 + 'px' }"
        >
          <span *ngIf="collapseChipsLabel === 'PRODUCTS_AND_CATEGORIES'">{{ getProductsAndCategories() }}</span>
          <span *ngIf="collapseChipsLabel === 'CATEGORIES'">{{ selectedItemsCount.categories + ' категории' }}</span>
          <span *ngIf="collapseChipsLabel === 'PRODUCTS'"> {{ selectedItemsCount.products + ' товаров' }}</span>
        </nm-chip>

        <input
          #input
          matInput
          *ngIf="showSearchInput"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchChange($event)"
          [attr.data-test-id]="testId"
          [disabled]="disabled"
          [placeholder]="showAllChip || (chipCount && obs.selectedItems.length > 0) ? '' : placeholder"
          (blur)="onTouched($event)"
        />

        <ng-container *ngIf="!showSearchInput">
          <div [ngClass]="{ 'selected-value': (selectedValue$.pipe() | async) }" class="placeholder" *ngIf="!isMulti">
            {{ displayValue(selectedValue$ | async) || placeholder }}
          </div>
          <div class="placeholder" *ngIf="isMulti && !(selectedItems$ | async).length">{{ placeholder }}</div>
        </ng-container>
      </div>
      <div icons class="icon-wrapper icon-wrapper-color">
        <nm-svg-icon
          class="clear-icon"
          *ngIf="showClearBtn && !dropdownMenu.isOpen"
          name="close"
          [size]="24"
          testId="icon-clear"
          (click)="clear($event)"
        ></nm-svg-icon>
        <nm-svg-icon
          *ngIf="showOpen && !dropdownMenu.isOpen"
          name="arrowDropDown"
          [ngClass]="{ opened: dropdownMenu.isOpen }"
          class="arrow-icon"
          [size]="24"
        ></nm-svg-icon>
      </div>
    </nm-form-field>
  </nm-dropdown-menu>
  <ng-template #menuTmpl>
    <nm-menu-content
      #menu
      *ngrxLet="loadingState | async as infiniteState"
      class="nm-autocomplete-list"
      [style.width]="width ? width + 100 + 'px' : fieldElem.nativeElement.children[0].clientWidth + 'px'"
      [items]="filteredOptions"
      [selectedItems]="selectedIdsValue"
      [panelClass]="menuPanelClass"
      [hasBackdrop]="true"
      [itemsLoading]="isLoading$ | async"
      [checkboxPosition]="isMulti ? 'right' : 'none'"
      [allowMultiselect]="isMulti"
      [closeAfterItemClick]="!isMulti"
      [maxItemsHeight]="300"
      [showCancelButton]="buttonBlock"
      [showOkButton]="buttonBlock"
      [isCloseAfterCancelClick]="true"
      [isCloseAfterOkClick]="true"
      [isStoreFilter]="true"
      [okButtonLabel]="okButtonLabel"
      [canLoadMore]="!infiniteState.reloading && infiniteState.canLoadMore"
      [multiSelect]="isMulti"
      [selectAllSettings]="selectAllSettings"
      [allSelected]="allSelectedCondition"
      (allSelectedChange)="onAllSelectedChange($event)"
      [searching]="!!searchText"
      (selectedItemsChange)="updateSelectedIdsFromMenu($event)"
      (loadMoreItems)="loadOptions()"
      (checkboxChange)="onItemCheckboxChange($event)"
      (cancelClick)="onCancelClick()"
      (okClick)="onOkClick($event)"
    >
      <nm-menu-item
        class="no-results-item"
        *ngIf="search && !showCreate && !filteredOptions?.length && !infiniteState.reloading"
        label="Результатов не найдено"
      ></nm-menu-item>
      <nm-menu-item
        class="no-results-item"
        *ngIf="!search && !showCreate && !filteredOptions?.length && !infiniteState.reloading"
        label="Результатов не найдено"
      ></nm-menu-item>
      <nm-menu-item
        class="create-menu-item"
        *ngIf="showCreate"
        [label]="getCreateLabel()"
        (click)="create.emit(searchText)"
      ></nm-menu-item>
    </nm-menu-content>
  </ng-template>
  <nm-control-hint *ngIf="hint" [label]="hint" [type]="hintType"></nm-control-hint>
</div>
