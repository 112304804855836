<ng-container *ngIf="metric && compareMetric && (bos.isMobile$ | async) && accountStorage.mobileVersion$.getValue()">
  <span>{{ TABLE_METRIC_NAME[metric] }}</span>
  <div class="description compare">{{ firstLabel }}</div>
  <div class="description compare">{{ secondLabel }}</div>
  <span>{{ TABLE_METRIC_NAME[compareMetric] }}</span>
  <div class="description compare">{{ firstLabel }}</div>
  <div class="description compare">{{ secondLabel }}</div>
</ng-container>
<ng-container *ngIf="!compareMetric || (bos.isMobile$ | async) === false || !accountStorage.mobileVersion$.getValue()">
  <div class="description">{{ firstLabel }}</div>
  <div class="description">{{ secondLabel }}</div>
</ng-container>
