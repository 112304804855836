import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TABLE_METRIC_NAME } from '@constants/table';
import { AccountStorage, BreakpointObserverService } from '@core/service';

@Component({
  selector: 'nm-table-compare-description',
  templateUrl: './table-compare-description.component.html',
  styleUrls: ['./table-compare-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCompareDescriptionComponent {
  readonly TABLE_METRIC_NAME = TABLE_METRIC_NAME;

  @Input() metric: string = '';
  @Input() compareMetric: string = '';
  @Input() firstLabel: string = '';
  @Input() secondLabel: string = '';

  constructor(public bos: BreakpointObserverService, public accountStorage: AccountStorage) {}
}
