<div class="nm-input-control" [class.nm-input-control-readonly]="readonly">
  <nm-control-label
    *ngIf="label || counterText"
    [label]="label"
    [required]="required"
    [showIcon]="showLabelIcon"
    [counterText]="counterText"
    [tooltip]="tooltip"
  ></nm-control-label>

  <nm-form-field
    [disabled]="disabled"
    [error]="error"
    [iconLeft]="iconLeft"
    [iconRight]="iconRight"
    [prefix]="prefix"
    [suffix]="suffix"
    (click)="onClick()"
    (rightIconClick)="onRightIconClick($event)"
  >
    <span #plainText *ngIf="readonly" class="nm-input-text">{{ value }}</span>

    <input
      #input
      *ngIf="!readonly"
      [(ngModel)]="value"
      [attr.data-test-id]="testId"
      [type]="type"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [inputMask]="inputMask"
      (ngModelChange)="changeVal($event)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
    />
  </nm-form-field>

  <nm-control-hint *ngIf="hint" [icon]="hintIcon" [label]="hint" [type]="hintType"></nm-control-hint>
</div>
