export const TABLE_METRIC_NAME = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  prime: 'Себестоимость',
  primePrice: 'Себестоимость',
  foodCost: 'Себестоимость',
  discount: 'Сумма скидок',
  ordersCount: 'Заказы',
  averageCheck: 'Средний чек',
  averageRevenue: 'Средний чек',
  guestsCount: 'Гости',
  guestCount: 'Гости',
  averageDiscount: 'Средняя скидка',
  ordersDiscountCount: 'Акционные продажи',
  averageItemsCount: 'Глубина чека',
  checkDepth: 'Глубина чека',
  profitPercent: 'Маржинальность',
  margin: 'Маржинальность',
  averageDiscountPercent: 'Доля скидок',
  averageDurationSeconds: 'Время обслуживания',
  averageFoodCost: 'Фудкост',
  averageRevenueEmployee: 'Выручка на сотрудника',
  earnedPoints: 'Начислено баллов',
  spentPoints: 'Списано баллов',
};
