import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SVG_ICONS_TYPE } from '@constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'nm-control-label',
  templateUrl: './control-label.component.html',
})
export class ControlLabelComponent {
  @Input() testId: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() icon: SVG_ICONS_TYPE = 'info';
  @Input() counterText: string;
  @Input() tooltip: string = '';
}
