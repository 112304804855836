import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[nmLongPress]',
})
export class LongPressDirective {
  @Input() delay = 500; // Время удержания в миллисекундах
  @Output() longPress = new EventEmitter<void>();

  private timeout: ReturnType<typeof setTimeout> | null = null;

  @HostListener('touchstart', ['$event'])
  onTouchStart() {
    this.timeout = setTimeout(() => {
      this.longPress.emit();
    }, this.delay);
  }

  @HostListener('touchend')
  @HostListener('touchcancel')
  onTouchEnd() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
