import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';

import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { ControlLabelComponent } from './control-label.component';

@NgModule({
  declarations: [ControlLabelComponent],
  imports: [CommonModule, SvgIconModule, TooltipModule],
  exports: [ControlLabelComponent],
})
export class ControlLabelModule {}
