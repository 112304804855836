import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { PaySystemReportMetric, PaySystemReportMetricNames, PaySystemReportSortFieldStrict } from '@typings';

const PAY_SYSTEMS_REPORT_METRICS: PaySystemReportMetricNames = {
  revenue: 'Выручка',
  ordersCount: 'Количество чеков',
  guestsCount: 'Количество гостей',
  averageCheck: 'Средний чек',
};

export const getPaySystemReportMetricsItems = (): MenuItem[] => {
  return Object.keys(PAY_SYSTEMS_REPORT_METRICS).map((metric) => ({
    id: metric,
    label: PAY_SYSTEMS_REPORT_METRICS[metric],
    type: 'item',
  }));
};

export const getMetricName = (metric: PaySystemReportMetric): string => {
  return PAY_SYSTEMS_REPORT_METRICS[metric];
};

export const getMetricsList = (): PaySystemReportMetric[] => {
  return Object.keys(PAY_SYSTEMS_REPORT_METRICS);
};

export const PAY_SYSTEM_REPORT_SORT_FIELD: PaySystemReportSortFieldStrict = {
  AVERAGE_CHECK: 'AVERAGE_CHECK',
  GUESTS_COUNT: 'GUESTS_COUNT',
  ORDERS_QUANTITY: 'ORDERS_QUANTITY',
  PAY_SYSTEM_NAME: 'PAY_SYSTEM_NAME',
  REVENUE: 'REVENUE',
};
