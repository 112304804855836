import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Params } from '@angular/router';
import { isEqual } from 'lodash';
import queryString, { ParsedQuery, ParseOptions } from 'query-string';

import { DaysAndTimeFilter, TimeRangeInput } from '@app/shared/component/filter-for-days/filter-for-days.component';
import { NumberRange } from '@app/shared/component/input-number-range/input-number-range.component';
import { GET_SORT_DIRECTION } from '@constants';
import { SortOrderInput } from '@typings';
import { getDateWithoutOffset } from '@utils';

export type Param = {
  name: string;
  value: string | boolean;
};

export const ARRAY_FORMAT_SEPARATOR = '|';

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  options: ParseOptions = { arrayFormat: 'separator', arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR };

  constructor(private location: Location) {}

  getParams() {
    return queryString.parse(location.search, this.options);
  }

  getRouterParams(): Params {
    const parsedQuery = this.getParams();
    return Object.keys(parsedQuery).reduce((acc: Params, param) => {
      const paramValue = parsedQuery[param];
      if (Array.isArray(paramValue)) {
        acc[param] = paramValue.join(ARRAY_FORMAT_SEPARATOR);
      } else {
        acc[param] = paramValue;
      }
      return acc;
    }, {});
  }
  getParamByName(name: string, multi?: false, parseBooleans?: false): string;
  getParamByName(name: string, multi?: true, parseBooleans?: false): string[];
  getParamByName(name: string, multi?: false, parseBooleans?: true): boolean;
  getParamByName(name: string, multi: false | true = false, parseBooleans: false | true = false): string | (string | null)[] | boolean {
    const pick = queryString.pick(location.href, [name], { parseBooleans });
    const url = queryString.parseUrl(pick, { ...this.options, parseBooleans });
    const parsedQuery: ParsedQuery<string> = url.query;
    const param: string | null | (string | null)[] | boolean = parsedQuery[name];

    if (!param) {
      return '';
    }

    if (multi) {
      if (Array.isArray(param)) {
        return param;
      }
      return [param];
    }

    return param;
  }

  setParams(params: Param[], parseBooleans: boolean = false) {
    const parse = queryString.parse(location.search, { ...this.options, parseBooleans });

    params.forEach((param) => {
      const { name, value } = param;

      value ? (parse[name] = String(value)) : delete parse[name];
    });

    if (!isEqual(this.getParams(), parse)) {
      const stringify = queryString.stringify(parse, this.options);

      this.location.go(location.pathname, stringify);
    }
  }

  arrayToString(array: string[]) {
    return array.join(ARRAY_FORMAT_SEPARATOR);
  }

  getParamForSet(name: string): string[] {
    const param = this.getParamByName(name);

    if (param && typeof param === 'string') {
      return [param];
    }

    if (param && typeof param === 'object') {
      return param as string[];
    }

    return [];
  }

  getParamsForSort(): SortOrderInput[] {
    const param = this.getParamByName('sort');

    if (param && param[0] && param[1]) {
      return [{ fieldName: param[0], direction: GET_SORT_DIRECTION(param[1]) }];
    }

    return [];
  }

  getParamsForSortNew() {
    const param = this.getParamByName('sort');

    if (param && param[0] && param[1]) {
      return [{ field: param[0], direction: GET_SORT_DIRECTION(param[1]) }];
    }

    return [];
  }

  getParamsForSortV2() {
    const param = this.getParamByName('sort');

    if (param && param[0] && param[1]) {
      return [{ sortField: param[0], sortDirection: GET_SORT_DIRECTION(param[1]) }];
    }

    return [];
  }

  getParamsForSortShifts() {
    const param = this.getParamByName('sort');

    if (param && param[0] && param[1]) {
      return [{ fieldName: param[0], direction: GET_SORT_DIRECTION(param[1]) }];
    }

    return [];
  }

  getDateRangeFromParam(paramName: string): DateRange<Date> | null {
    const period = this.getParamByName(paramName);
    let datepickerRange: DateRange<Date> | null = null;

    if (period) {
      const start = period[0];
      const end = period[1];

      datepickerRange = new DateRange(getDateWithoutOffset(start), getDateWithoutOffset(end));
    }

    return datepickerRange;
  }

  getDaysAndTimeFilterFromParams(paramName: string): DaysAndTimeFilter | null {
    const param = this.getParamByName(paramName);
    let daysAndTime: DaysAndTimeFilter | null = null;

    if (param) {
      const hourRange: TimeRangeInput = { timeFrom: param[param.length - 2], timeTo: param[param.length - 1] };
      const daysOfWeek = param.slice(0, param.length - 2);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      daysAndTime = { hourRange, daysOfWeek: daysOfWeek };
    }

    return daysAndTime;
  }

  getCompareDaysAndTimeFilterFromParams(paramName: string): DaysAndTimeFilter[] | null {
    const param = this.getParamByName(paramName);
    let daysAndTime: DaysAndTimeFilter[] | null = null;

    if (param) {
      daysAndTime = [];
      const first = param[0].split('_');
      const second = param[1].split('_');
      const hourRangeFirst: TimeRangeInput = { timeFrom: first[first.length - 2], timeTo: first[first.length - 1] };
      const hourRangeSecond: TimeRangeInput = { timeFrom: second[second.length - 2], timeTo: second[second.length - 1] };
      const daysOfWeekFirst = first.slice(0, first.length - 2);
      const daysOfWeekSecond = second.slice(0, second.length - 2);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      daysAndTime.push({ hourRange: hourRangeFirst, daysOfWeek: daysOfWeekFirst });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      daysAndTime.push({ hourRange: hourRangeSecond, daysOfWeek: daysOfWeekSecond });
    }

    return daysAndTime;
  }

  getDateCompareRangeFromParam(): DateRange<Date>[] | null {
    const period = this.getParamByName('comparePeriods');
    let compareRanges: DateRange<Date>[] | null = null;

    if (period) {
      const firstDateStart = period[0];
      const firstDateEnd = period[1];
      const secondDateStart = period[2];
      const secondDateEnd = period[3];

      compareRanges = [
        new DateRange(getDateWithoutOffset(firstDateStart), getDateWithoutOffset(firstDateEnd)),
        new DateRange(getDateWithoutOffset(secondDateStart), getDateWithoutOffset(secondDateEnd)),
      ];
    }
    return compareRanges;
  }

  getParamForBoolean(name: string, defaultParam: boolean = false): boolean {
    const param = this.getParamByName(name, false, true);

    return param ? param : defaultParam;
  }

  getParamsForString(name: string) {
    const param = this.getParamByName(name);
    return param ?? '';
  }

  getParamForPage(name: string): number {
    const param = this.getParamByName(name, false, false);
    let page = parseInt(param);
    return isNaN(page) || page < 1 ? 1 : page;
  }

  getParamForNumberRange(name: string): NumberRange | null {
    const param = this.getParamByName(name, false, false);
    return { from: param[0], to: param[1] };
  }
}
