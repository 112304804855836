import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { Drop } from '@app/shared/directive/drag-and-drop/drag-and-drop.types';
import { DragAndDropListDirective } from '@app/shared/directive/drag-and-drop/drag-and-drop-list.directive';
import { TableService } from '@app/shared/service/table.service';
import { BreakpointObserverService } from '@core/service';

import { TemplateColData } from '../table.component';
import { Row, RowHeightEvent, RowVisibilityDirection, TableConfig } from '../table.types';

@Component({
  selector: 'nm-table-body',
  templateUrl: './table-body.component.html',
  styleUrls: ['./table-body.component.scss'],
  providers: [TableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBodyComponent {
  @Input() config: TableConfig;
  @Input() rows: Row[];
  @Input() tableElement: ElementRef;
  @Input() columnsDataTemplates: TemplateColData[] = [];
  @Input() gridTemplateColumns: string = 'auto';
  @Input() isMouseOverIndex = 0;
  @Input() isMouseOver = false;
  @Input() metric: string;
  @Input() compareMetric: string;
  @Input() compare: boolean;
  @Input() set isDragging(condition: boolean) {
    this.cdRef.detectChanges();
    this.isRowBeingdraged = condition;
  }

  @Output() selectRow = new EventEmitter<Row>();
  @Output() expandRow = new EventEmitter<Row>();
  @Output() dragStart = new EventEmitter<Row>();
  @Output() dragEnd = new EventEmitter<Row>();
  @Output() dropRow = new EventEmitter<Drop<Row>>();
  @Output() dragRow = new EventEmitter<Drop<Row>>();
  @Output() rowHidden = new EventEmitter<RowVisibilityDirection>();
  @Output() rowVisible = new EventEmitter<RowVisibilityDirection>();
  @Output() rowHeightInited = new EventEmitter<RowHeightEvent>();

  @ViewChildren('row', { read: ElementRef }) rowsElems: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('dropList', { read: DragAndDropListDirective }) dropList: DragAndDropListDirective;

  isRowBeingdraged = false;

  constructor(private cdRef: ChangeDetectorRef, public bos: BreakpointObserverService) {}
  onSelectRow(row: Row) {
    this.selectRow.emit(row);
  }

  onExpandRow(row: Row) {
    this.expandRow.emit(row);
  }

  onDragStart(row: Row) {
    this.dragStart.emit(row);
  }

  onDropRow(event: Drop<unknown>) {
    this.dropRow.emit(event as Drop<Row>);
  }

  onDrag(event: Drop<unknown>) {
    this.dragRow.emit(event as Drop<Row>);
  }

  onDragEnd() {
    this.dragEnd.emit();
  }

  scrollToRow(dataId: string): void {
    const row = this.rowsElems.find((row) => row.nativeElement.getAttribute('scroll-id') === dataId);
    if (row) {
      row.nativeElement.scrollIntoView();
    }
  }
}
