import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { TableComparisonCellModule } from '@app/shared/component/table-comparison-cell/table-comparison-cell.module';
import { WidgetComparisonModule } from '@app/shared/component/widget-comparison/widget-comparison.module';
import { TooltipModule } from '@app/shared/directive/tooltip/tooltip.module';
import { SharedPipesModule } from '@app/shared/pipe/pipes.module';

import { SkeletonModule } from '../skeleton/skeleton.module';

import { MetricComponent } from './metric.component';

@NgModule({
  declarations: [MetricComponent],
  imports: [
    CommonModule,
    SvgIconModule,
    TooltipModule,
    SkeletonModule,
    TableComparisonCellModule,
    WidgetComparisonModule,
    SharedPipesModule,
  ],
  exports: [MetricComponent],
})
export class MetricModule {}
