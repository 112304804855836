import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil, timer } from 'rxjs';
import { first } from 'rxjs/operators';

import { SKELETON_TYPE } from '@app/shared/component/skeleton/skeleton.component';
import { TableConfig } from '@app/shared/component/table/table.types';
import { tableGridTemplateColumns } from '@utils';

@Component({
  selector: 'nm-skeleton-template-table',
  templateUrl: './skeleton-template-table.component.html',
  styleUrls: ['./skeleton-template-table.component.scss'],
})
export class SkeletonTemplateTableComponent implements OnInit, OnChanges {
  readonly defaultRows = 3;
  readonly defaultColumns = 6;

  @Input() timer: number = 300;
  @Input() rows: number;
  @Input() page: number;
  @Input() columns: number;
  @Input() showAlways: boolean = false;

  @Input() set isLoading(value: boolean) {
    this.cancelTimer$.next();

    if (value === true) {
      timer(this.timer)
        .pipe(first(), takeUntil(this.cancelTimer$))
        .subscribe(() => this.show$.next(value));
    } else {
      this.show$.next(value);
    }
  }

  @Input() tableConfig: TableConfig | null;

  show$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  page$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  type: SKELETON_TYPE = SKELETON_TYPE.RECT;
  rowsArray: string[];
  columnsArray: string[];

  cancelTimer$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.rowsArray = Array(this.rows || this.defaultRows).fill('');
    this.columnsArray = Array(this.columns || this.columnsFromConfig() || this.defaultColumns).fill('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.page$.next(changes.page.currentValue);
    }
  }

  get gridTemplateColumns(): string {
    if (this.tableConfig) {
      return tableGridTemplateColumns(this.tableConfig) || 'auto';
    }
    return 'auto';
  }

  columnsFromConfig(): number {
    return (this.tableConfig?.columnsConfig || []).length;
  }
}
