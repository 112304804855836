import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedPipesModule } from '@app/shared/pipe/pipes.module';

import { TableComparisonCellComponent } from './table-comparison-cell.component';

@NgModule({
  declarations: [TableComparisonCellComponent],
  imports: [CommonModule, SharedPipesModule],
  exports: [TableComparisonCellComponent],
})
export class TableComparisonCellModule {}
