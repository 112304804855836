import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { MenuItem, MenuItemType } from '@app/shared/component/menu-item/menu-item.model';
import { NotifyService } from '@services/shared';
import {
  MutationCreateStoreV2Args,
  MutationResult,
  MutationUpdateStoreArgs,
  QueryResult,
  QueryStoreArgs,
  QueryStoresArgs,
  Store,
  StoreAvailabilityFilter,
} from '@typings';

import { StoresApi } from './stores.api';

@Injectable({
  providedIn: 'root',
})
export class StoresStorage {
  #stores = new BehaviorSubject<MenuItem[]>([]);
  stores$ = this.#stores.asObservable();
  allStores$ = new BehaviorSubject<Store[]>([]);

  updateStores(value: MenuItem[]): void {
    this.#stores.next(value);
  }

  constructor(private storesApi: StoresApi, private notifyService: NotifyService) {}

  async initAvailableStores(input: StoreAvailabilityFilter) {
    return new Promise((resolve) => {
      this.storesApi
        .getAvailableStores({ filter: input })
        .pipe(take(1))
        .subscribe((stores) => {
          const ids = stores.data.availableStores.availableIds || [];
          this.allStores$.next(stores.data.availableStores.stores || []);
          const items = (stores.data.availableStores.stores || [])
            .filter((store) => ids.some((id) => id === store.id))
            .map((store) => ({
              id: store.id,
              type: 'item' as MenuItemType,
              label: store.name,
            }));
          this.updateStores(items);
          resolve(stores);
        });
    });
  }

  getAllStores(): QueryResult<'allStores'> {
    return this.storesApi.getAllStores().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getAllStoresShort(): QueryResult<'allStores'> {
    return this.storesApi.getAllStoresShort().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getAvailableStores(): QueryResult<'availableStores'> {
    return this.storesApi.getAvailableStores({ filter: { requiredScope: '' } }).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
      tap((res) => {
        const ids = res.data.availableStores.availableIds || [];
        this.allStores$.next(res.data.availableStores.stores || []);
        return (res.data.availableStores.stores || []).filter((store) => ids.some((id) => id === store.id));
      }),
    );
  }

  getStores(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStores(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getStoresShort(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStoresShort(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getAllStoresForPaymentSystem(): Observable<Store[]> {
    return this.storesApi.allStoresForPaymentSystem().pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
      map((res) => res.data.allStores),
    );
  }

  getStoresTotalNumber(variables: QueryStoresArgs): QueryResult<'stores'> {
    return this.storesApi.getStoresTotalNumber(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка точек продаж',
      }),
    );
  }

  getStore(variables: QueryStoreArgs): QueryResult<'store'> {
    return this.storesApi.getStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении точки продаж',
      }),
    );
  }

  createStore(variables: MutationCreateStoreV2Args): MutationResult<'createStoreV2'> {
    return this.storesApi.createStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании точки продаж',
      }),
    );
  }

  updateStore(variables: MutationUpdateStoreArgs): MutationResult<'updateStore'> {
    return this.storesApi.updateStore(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении информации точки продаж',
      }),
    );
  }
}
