import { gql } from 'apollo-angular';

import { CoreSchema } from '@typings';

export type ChartOutput = CoreSchema.ChartOutput;
export type Dataset = CoreSchema.Dataset;
export type SeriesData = CoreSchema.SeriesData;
export type ChartMetric = CoreSchema.ChartMetric;

export const fragmentChartOutput = gql`
  fragment ChartOutput on ChartOutput {
    xLabels
    datasets {
      metricLabel
      totals
      series {
        seriesLabel
        data
      }
    }
  }
`;
