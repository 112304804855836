<ng-container *ngIf="(isTopBarItem$ | async) || sidenavMode">
  <nm-section [isHeader]="true" [isFooter]="false">
    <nm-tab-group
      header
      [selectedIndex]="selectedIndex$ | async"
      testId="open-access::navigation-tabs"
      (selectedIndexChange)="navigateToTab($event)"
    >
      <nm-tab *ngFor="let tab of tabs" [label]="tab.label" [disabled]="tab.disabled"></nm-tab>
    </nm-tab-group>

    <ng-container main style="margin-bottom: 60px">
      <ng-container *ngIf="!sidenavMode"><router-outlet></router-outlet></ng-container>

      <ng-container *ngIf="sidenavMode">
        <ng-container *ngIf="(selectedIndex$ | async) === 0"><nm-add-users></nm-add-users></ng-container>
      </ng-container>
    </ng-container>
  </nm-section>
</ng-container>
