import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';

import { AutocompleteModule } from '@app/shared/component/autocomplete/autocomplete.module';
import { DropdownMenuModule } from '@app/shared/component/dropdown-menu/dropdown-menu.module';
import { IconButtonModule } from '@app/shared/component/icon-button/icon-button.module';
import { InputSearchModule } from '@app/shared/component/input-search/input-search.module';
import { LetModule } from '@ngrx/component';

import { CheckboxModule } from '../checkbox/checkbox.module';
import { DividerModule } from '../divider/divider.module';
import { FilterHeaderModule } from '../filter-header/filter-header.module';
import { FilterLabelModule } from '../filter-label/filter-label.module';
import { FilterPanelModule } from '../filter-panel/filter-panel.module';
import { FormFieldModule } from '../form-field/form-field.module';
import { MenuModule } from '../menu/menu.module';
import { RadioModule } from '../radio/radio.module';
import { TabModule } from '../tab/tab.module';
import { TabGroupModule } from '../tab-group/tab-group.module';

import { FilterSelectorModeComponent } from './filter-selector-mode/filter-selector-mode.component';
import { FilterPipe } from './filter.pipe';
import { FilterSelectWithCompareComponent } from './filter-select-with-compare.component';

@NgModule({
  declarations: [FilterSelectWithCompareComponent, FilterPipe, FilterSelectorModeComponent],
  imports: [
    CommonModule,
    FormFieldModule,
    MenuModule,
    MatMenuModule,
    FilterPanelModule,
    FilterHeaderModule,
    RadioModule,
    DividerModule,
    MatRadioModule,
    FilterLabelModule,
    LetModule,
    IconButtonModule,
    InputSearchModule,
    CheckboxModule,
    TabModule,
    TabGroupModule,
    AutocompleteModule,
    DropdownMenuModule,
  ],
  exports: [FilterSelectWithCompareComponent, FilterSelectorModeComponent],
})
export class FilterSelectWithCompareModule {}
