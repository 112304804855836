import { gql } from 'apollo-angular';

import { fragmentPage } from './mutations';

export const queryAllEmployeesPageable = gql`
  query allEmployeesPageable($filter: EmployeeFilterInput!, $pageRequest: PageRequestInput!) {
    allEmployeesPageable(filter: $filter, pageRequest: $pageRequest) {
      content {
        id
        active
        firstName
        lastName
        middleName
        userGroups {
          code
          storeId
        }
      }
      ...Page
    }
  }
  ${fragmentPage}
`;

export const queryEmployeeV2 = gql`
  query employee($id: UUID!) {
    employee(id: $id) {
      id
      lastName
      firstName
      middleName
      workEmail
      workPhone
      vatin
      userGroups {
        id
        userGroupId
        name
        code
        storeId
      }
      active
      pin
      snils
      dateOfBirth
      medicalBookExpiresAt
      dateOfEmployment
      address
    }
  }
`;

export const queryUserGroups = gql`
  query userGroups {
    userGroups {
      id
      name
      code
    }
  }
`;

export const mutationCreateEmployee = gql`
  mutation createEmployee($createEmployeeInput: CreateEmployeeInput!) {
    createEmployee(createEmployeeInput: $createEmployeeInput)
  }
`;

export const mutationRemoveEmployee = gql`
  mutation removeEmployee($employeeId: UUID!) {
    removeEmployee(employeeId: $employeeId)
  }
`;

export const mutationUpdateEmployee = gql`
  mutation updateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
    updateEmployee(updateEmployeeInput: $updateEmployeeInput)
  }
`;

export const mutationUpdateEmployeePinCode = gql`
  mutation updateEmployeePinCode($employeeId: UUID!) {
    updateEmployeePinCode(employeeId: $employeeId) {
      pin
    }
  }
`;
