<div
  #rowElement
  class="row"
  [class.highlighted]="row?.highlighted"
  [class.drag-hovered]="dragHovering"
  [class.selected]="row.selected"
  [class.drag-mode]="dragModeStart || table.isDragging"
  [style.width]="tableWidth ? tableWidth + 'px' : ''"
  [class.color]="row?.rowColor"
  [style.background-color]="row?.rowColor ? row.rowColorBackground : 'none'"
  [class.mobile-row]="bos.getIsMobile()"
  [class.table-dragging]="isDragging"
  [ghostTemplate]="ghostTemplate"
  (nmDragEnd)="onDragEnd()"
  (nmDrop)="onDropRow($event)"
  (nmDragStart)="onDragStart()"
  (longPress)="config.selectable && onRowSelect(!row.selected)"
  [data]="row"
  (click)="rowClick(row.rowDataId)"
  [rowId]="row.rowDataId"
  nmDragAndDropItem
  nmLongPress
>
  <div class="pointer up"></div>
  <div class="pointer down"></div>
  <div
    class="drop-inside"
    (dragover)="onDragOver()"
    (dragleave)="onDragLeave()"
    *ngIf="row?.isCategoryRow && table.isDragging"
  ></div>
  <div class="drag-placeholder" *cdkDragPlaceholder></div>
  <div
    *ngIf="
      config.dragAndDropOptions?.enable && (breakpointObserverService.isMobile$ | async) === false && !table.isDragging
    "
    (mouseDown)="onMouseDown()"
    class="drag-handler"
  >
    <nm-drag-handler (mousedown)="onMouseDown()"></nm-drag-handler>
  </div>

  <ng-template #ghostTemplate>
    <div class="drag-preview-container">
      <div class="drag-preview">
        <nm-svg-icon *ngIf="row.children?.length" [size]="24" [name]="dragPreviewIcon"></nm-svg-icon
        ><nm-svg-icon *ngIf="!row.children?.length" [size]="24" [name]="'draft'"></nm-svg-icon>Объектов:
        {{ (this.row.children?.length || 0) + 1 }}
      </div>
    </div>
  </ng-template>

  <div class="inner" id="row" [style.grid-template-columns]="gridTemplateColumns">
    <div
      class="column select-column"
      [style.width]="selectColWidth + 'px'"
      *ngIf="config.selectable && !((config.mobileVersion || config.itemVersion) && (bos.isMobile$ | async))"
      [class.pinned]="true"
    >
      <div
        class="number-wrapper"
        *ngIf="(config?.enumerable?.enabled && row?.enumerator !== undefined) || row.skipEnumerator"
      >
        {{ row.enumerator + 1 }}
      </div>
      <div class="checkbox-wrapper" *ngIf="row.rowDataId !== 'empty' && config.selectable && row.rowDataId !== 'total'">
        <nm-checkbox
          [checked]="!!row?.selected"
          (checkboxChange)="onSelectRowChange($event)"
          [indeterminate]="row?.selected === 'indeterminate'"
          (click)="checkboxClick()"
        ></nm-checkbox>
      </div>
    </div>

    <div
      class="column number-column"
      *ngIf="
        config?.enumerable?.enabled &&
        !config.selectable &&
        !((config.mobileVersion || config.itemVersion) && (bos.isMobile$ | async))
      "
      [class.grouped-row]="row?.isGroupedRow"
      [class.pinned]="true"
    >
      <div class="number-wrapper" *ngIf="row?.enumerator !== undefined">
        {{ row.enumerator + 1 }}
      </div>
    </div>

    <ng-container *ngIf="config.itemVersion && bos.isMobile$ | async">
      <div class="column" [class.compare-mobile]="true" [style.gap]="'2px'">
        <ng-container *ngFor="let column of columns.slice(0, columns.length - 1); let i = index">
          <ng-container
            *ngIf="!contentIsDefined(column)"
            [ngTemplateOutlet]="column.template"
            [ngTemplateOutletContext]="{ data: column.data }"
          ></ng-container>
          <ng-container *ngIf="contentIsDefined(column)">
            <div class="col-content" [style.text-align]="config?.columnsConfig[i]?.align || 'initial'">
              {{ column.content }}
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="column" [class.compare-mobile]="true">
        <ng-container
          *ngIf="!contentIsDefined(columns[columns.length - 1])"
          [ngTemplateOutlet]="columns[columns.length - 1].template"
          [ngTemplateOutletContext]="{ data: columns[columns.length - 1].data }"
        ></ng-container>
        <ng-container *ngIf="contentIsDefined(columns[columns.length - 1])">
          <div class="col-content" [style.text-align]="config?.columnsConfig[columns.length - 1]?.align || 'initial'">
            {{ columns[columns.length - 1].content }}
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="config.mobileVersion && bos.isMobile$ | async">
      <div
        class="column"
        [class.compare-mobile]="metric && compareMetric"
        [class.expand-column]="getMainColumn() === expandColumn"
        [class.grouped-row]="row?.isGroupedRow"
        [style.justify-content]="config?.columnsConfig[getMainColumn()]?.align || 'left'"
      >
        <div
          class="column-expand"
          (click)="onExpandClick()"
          [ngClass]="{ 'hide-expand': row.hideExpand }"
          *ngIf="(row?.children ? row?.children?.length : row?.lazyChildren) && getMainColumn() === expandColumn"
        >
          <nm-svg-icon *ngIf="row?.expanded" name="keyboardArrowDown" [size]="24"></nm-svg-icon>
          <nm-svg-icon *ngIf="!row?.expanded" name="keyboardArrowRight" [size]="24"></nm-svg-icon>
        </div>
        <div
          class="column-expand"
          [ngClass]="{ 'hide-expand': row.hideExpand }"
          *ngIf="
            (row?.children ? !row?.children?.length : !row?.lazyChildren) &&
            getMainColumn() === expandColumn &&
            config.expandable
          "
        ></div>

        <ng-container
          *ngIf="!contentIsDefined(columns[getMainColumn()])"
          [ngTemplateOutlet]="columns[getMainColumn()].template"
          [ngTemplateOutletContext]="{ data: columns[getMainColumn()].data }"
        ></ng-container>
        <ng-container *ngIf="metric && compareMetric && !compare">
          <span>{{ TABLE_METRIC_NAME[metric] }}</span>
          <span>{{ TABLE_METRIC_NAME[compareMetric] }}</span>
        </ng-container>
        <ng-container *ngIf="contentIsDefined(columns[getMainColumn()])">
          <div class="col-content" [style.text-align]="config?.columnsConfig[getMainColumn()]?.align || 'initial'">
            {{ columns[getMainColumn()].content }}
          </div>
        </ng-container>
      </div>
      <div
        class="column"
        [class.compare-data-mobile]="metric && compareMetric"
        *ngIf="metric"
        [style.justify-content]="config?.columnsConfig[getMetricColumn(metric)]?.align || 'left'"
      >
        <ng-container
          *ngIf="!contentIsDefined(columns[getMetricColumn(metric)]) && metric"
          [ngTemplateOutlet]="columns[getMetricColumn(metric)].template"
          [ngTemplateOutletContext]="{ data: columns[getMetricColumn(metric)].data }"
        ></ng-container>
        <ng-container *ngIf="contentIsDefined(columns[getMetricColumn(metric)]) && metric">
          <div
            class="col-content"
            [style.text-align]="config?.columnsConfig[getMetricColumn(metric)]?.align || 'initial'"
          >
            {{ columns[getMetricColumn(metric)].content }}
          </div>
        </ng-container>

        <ng-container
          *ngIf="!contentIsDefined(columns[getMetricColumn(compareMetric)]) && compareMetric"
          [ngTemplateOutlet]="columns[getMetricColumn(compareMetric)].template"
          [ngTemplateOutletContext]="{ data: columns[getMetricColumn(compareMetric)].data }"
        ></ng-container>
        <ng-container *ngIf="contentIsDefined(columns[getMetricColumn(compareMetric)]) && compareMetric">
          <div
            class="col-content"
            [style.text-align]="config?.columnsConfig[getMetricColumn(compareMetric)]?.align || 'initial'"
          >
            {{ columns[getMetricColumn(compareMetric)].content }}
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="(!config.itemVersion && !config.mobileVersion) || (this.bos.isMobile$ | async) === false">
      <div
        *ngFor="let column of columns; let i = index"
        class="column"
        [class.hidden-column]="config?.columnsConfig[i]?.hidden"
        [class.pinned]="config?.columnsConfig[i]?.pinned"
        [ngStyle]="{ left: config?.columnsConfig[i]?.pinned ? this.getLeftForPinned(config?.columnsConfig, i) : '0' }"
        [class.expand-column]="i === expandColumn"
        [class.grouped-row]="row?.isGroupedRow"
        [class.transparent-border]="column.isTransparentBorder"
        [class.top-border]="column.isTopBorder"
        [ngClass]="[
          'column-numeric-' + (config?.columnsConfig[i]?.align || 'initial'),
          column?.type === 'numeric' ? 'column-numeric' : ''
        ]"
        [style.padding-left]="i === expandColumn ? rowPadding + 'px' : ''"
        [class.color]="config?.columnsConfig[i]?.columnColor || row.columnsData[i].color"
        [class.border-left]="checkBorder(i)"
        [class.border-right]="checkBorder(i, false)"
        [class.edit-column]="config?.columnsConfig[i]?.isEdit"
        [class.hover]="isMouseOver && isMouseOverIndex === i"
      >
        <div class="column-content" [style.text-align]="config?.columnsConfig[i]?.align || 'initial'">
          <div
            class="column-expand"
            (click)="onExpandClick()"
            [ngClass]="{ 'hide-expand': row.hideExpand }"
            *ngIf="(row?.children ? row?.children?.length : row?.lazyChildren) && i === expandColumn"
          >
            <nm-svg-icon *ngIf="row?.expanded" name="keyboardArrowDown" [size]="24"></nm-svg-icon>
            <nm-svg-icon *ngIf="!row?.expanded" name="keyboardArrowRight" [size]="24"></nm-svg-icon>
          </div>
          <div
            class="column-expand"
            [ngClass]="{ 'hide-expand': row.hideExpand }"
            *ngIf="
              (row?.children ? !row?.children?.length : !row?.lazyChildren) && i === expandColumn && config.expandable
            "
          ></div>

          <ng-container
            *ngIf="!contentIsDefined(column)"
            [ngTemplateOutlet]="column.template"
            [ngTemplateOutletContext]="{ data: column.data }"
          ></ng-container>
          <ng-container *ngIf="contentIsDefined(column)">
            <div class="col-content" [style.text-align]="config?.columnsConfig[i]?.align || 'initial'">
              {{ column.content }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="row?.expanded">
  <div class="rows">
    <ng-container *ngFor="let child of row?.children">
      <ng-container
        rowChild
        [rowPadding]="rowPadding"
        [columnsDataTemplates]="columnsDataTemplates"
        [gridTemplateColumns]="gridTemplateColumns"
        [metric]="metric"
        [config]="config"
        [row]="child"
        [update]="{ isMouseOver, isMouseOverIndex }"
        (selectRow)="selectRow.emit($event)"
        (expandRow)="expandRow.emit($event)"
        (dragStart)="dragStart.emit($event)"
        (dragEnd)="dragEnd.emit($event)"
        (click)="rowClick(child.rowDataId)"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>
