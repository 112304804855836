import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, Observable, Subject, take } from 'rxjs';
import { first } from 'rxjs/operators';

import { RoleStorage } from '@app/modules/employees/services/roles/role.storage';
import { DeleteRoleDialogComponent } from '@app/modules/settings/pages/components/delete-role-dialog/delete-role-dialog.component';
import { ModalRef, ModalService } from '@app/shared/component/dialog/abstract';
import { Row } from '@app/shared/component/table/table.types';
import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { CreateRoleInput, Role, RoleFilterInput, RolePage, UpdateRoleInput } from '@app/typings/role';
import { MAX_CHARACTERS } from '@constants';
import { AccessERP, AccessPos } from '@constants/role';
import { FormConfirmSaveService, ValidationErrorsService } from '@core/service';
import { NotifyService, SidenavService } from '@services/shared';
import { PageRequestInput } from '@typings';

export type RoleForm = {
  name: FormControl<string>;
  accessERP: FormControl<boolean>;
  accessPOS: FormControl<boolean>;
  posOrderElementsAfterCheck: FormControl<boolean>;
};

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  modalRef: ModalRef<unknown>;
  form: FormGroup<RoleForm>;
  role: Role | undefined;

  sidenavMode = false;
  updateRoleInTable = new Subject<Role>();
  removeRoleFromTable = new Subject<string>();

  isSubmitDisabled$ = new BehaviorSubject(false);
  isEditing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private roleStorage: RoleStorage,
    private modalService: ModalService,
    private formConfirmSaveService: FormConfirmSaveService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private validationErrorsService: ValidationErrorsService,
    private notifyService: NotifyService,
    private tableSidenavService: TableSidebarLayoutService,
  ) {}

  initForm(role?: Role): void {
    this.role = role;

    this.form = this.fb.group<RoleForm>({
      name: this.fb.nonNullable.control('', [Validators.required, Validators.maxLength(MAX_CHARACTERS.NAME)]),
      accessPOS: this.fb.nonNullable.control(true),
      accessERP: this.fb.nonNullable.control(true),
      posOrderElementsAfterCheck: this.fb.nonNullable.control(true),
    });

    if (this.role) {
      const { controls } = this.form;
      const { name, scopesErp, scopesPos } = this.role;

      controls.name.setValue(name);
      controls.accessERP.setValue(!!scopesErp.filter((item) => item !== 'profile').length);
      controls.accessPOS.setValue(scopesPos.includes('pos'));
      controls.posOrderElementsAfterCheck.setValue(scopesPos.includes('pos.grouporder.afterCheck'));
    }

    this.isEditing$.next(!!this.role);
    this.isSubmitDisabled$.next(false);

    this.formConfirmSaveService.setForm(this.form);
  }

  getRolesList(pageRequestInput: PageRequestInput, filter?: RoleFilterInput): Observable<RolePage> {
    return this.roleStorage.roles({ filter, pageRequestInput }).pipe(map((res) => res.data.roles));
  }

  getRole(id: string): Observable<Role> {
    return this.roleStorage.role({ id }).pipe(map((res) => res.data.role!));
  }

  deleteRole(role: Role, callbackFn: () => void): void {
    if (!role || !role.id) {
      return;
    }

    this.modalRef.close();
    this.roleStorage.deleteRole({ id: role.id }).pipe(first()).subscribe(callbackFn);
  }

  showDeleteModal(role: Role, callbackFn: () => void): void {
    this.modalRef = this.modalService.openDialog(DeleteRoleDialogComponent, { data: { role, callbackFn } });
  }

  hideModal(): void {
    if (!this.modalRef) {
      return;
    }

    this.modalRef.close();
  }

  back(shouldConfirm: boolean = true): void {
    this.sidenavService.back(shouldConfirm);
  }

  submitForm(rowsErp: Row<AccessERP>[], rowsPOS: Row<AccessPos>[]) {
    if (this.form.invalid) {
      this.validationErrorsService.markFormControls(this.form);
      this.notifyService.addNotification({
        type: 'alert',
        title: 'Необходимо заполнить обязательные поля',
      });

      return;
    }

    this.disableForm();

    const accessErp = rowsErp
      .filter((r) => r.data!.control.value !== 'notAccess')
      .map((r) => (r.data!.control.value === 'all' ? r.data!.id : `${r.data!.id}.read`));

    function getAllIds(rows: Row<AccessPos>[]): string[] {
      const result: string[] = [];

      function traverse(items: Row<AccessPos>[]) {
        for (const item of items) {
          if (item.data!.control!.value === 'all') {
            result.push(item.data!.id);
            continue;
          }
          if (item.children?.length) {
            traverse(item.children);
          }
        }
      }

      traverse(rows);
      return result;
    }

    const accessPos = getAllIds(rowsPOS);
    this.role
      ? this.updateRole(this.getRoleUpdateInput(accessPos, accessErp))
      : this.createRole(this.getRoleCreateInput(accessPos, accessErp));
  }

  getRoleCreateInput(accessPos: string[], accessErp: string[]): CreateRoleInput {
    const { controls } = this.form;
    const { name, posOrderElementsAfterCheck } = controls;

    const scopes = ['profile', ...accessErp.concat(accessPos)];

    if (accessPos.length) {
      scopes.push('pos');
    }

    if (posOrderElementsAfterCheck.value) {
      scopes.push('pos.grouporder.afterCheck');
    }

    return {
      name: name.value,
      scopes,
    };
  }

  getRoleUpdateInput(accessPos: string[], accessErp: string[]): UpdateRoleInput {
    return {
      id: this.role?.id || '',
      ...this.getRoleCreateInput(accessPos, accessErp),
    };
  }

  disableForm(): void {
    this.form.disable();
    this.isSubmitDisabled$.next(true);
  }

  enableForm(): void {
    this.form.enable();
    this.isSubmitDisabled$.next(false);
  }

  createRole(input: CreateRoleInput) {
    this.roleStorage.createRole({ input }).subscribe(
      () => this.back(false),
      () => this.enableForm(),
    );
  }

  updateRole(input: UpdateRoleInput) {
    this.roleStorage.updateRole({ input }).subscribe(
      (res) => {
        this.updateRoleInTable.next(res?.data?.updateRole.updatedRole as Role);
        if (this.sidenavMode) {
          this.tableSidenavService.closeTableSidenav();
          this.formConfirmSaveService.closeForm(false);
        } else {
          this.back(false);
        }
      },
      () => this.enableForm(),
    );
  }

  showExitModal() {
    this.formConfirmSaveService
      .closeForms(true)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.tableSidenavService.closeTableSidenav();
          this.formConfirmSaveService.forms = [];
        }
      });
  }
}
