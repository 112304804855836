import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserStorage } from '@app/modules/employees/services/employees/user.storage';
import { RoleStorage } from '@app/modules/employees/services/roles/role.storage';
import { RoleFilterInput, RolePage } from '@app/typings/role';
import {
  AddUserScopesInput,
  AddUserScopesResponse,
  RemoveUserScopesInput,
  RemoveUserScopesResponse,
  User,
  UserFilterInput,
  UserPage,
  UserScopesFilterInput,
  UserScopesPage,
} from '@app/typings/user';
import { PageRequestInput } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private userStorage: UserStorage, private roleStorage: RoleStorage) {}

  getUsers(filter: UserFilterInput, pageRequestInput: PageRequestInput): Observable<UserPage> {
    return this.userStorage.users({ filter, pageRequestInput }).pipe(map((res) => res.data.users));
  }

  getUser(id: string): Observable<User> {
    return this.userStorage.user({ id }).pipe(map((res) => res.data.user!));
  }

  getIamUser(organizationId: string): Observable<User> {
    return this.userStorage.iamUser({ organizationId }).pipe(map((res) => res.data.iamUser!));
  }

  getRoles(filter: RoleFilterInput, pageRequestInput: PageRequestInput): Observable<RolePage> {
    return this.roleStorage.roles({ filter, pageRequestInput }).pipe(map((res) => res.data.roles));
  }

  getRolesShort(): Observable<RolePage> {
    return this.roleStorage.roles({ pageRequestInput: { page: 0, size: 100000 } }).pipe(map((res) => res.data.roles));
  }

  getRolesByUser(userId: string, pageRequestInput: PageRequestInput, filter?: RoleFilterInput): Observable<RolePage> {
    return this.roleStorage.rolesByUser({ filter, pageRequestInput, userId }).pipe(map((res) => res.data.rolesByUser));
  }

  getUserScopes(filter: UserScopesFilterInput, page: PageRequestInput): Observable<UserScopesPage> {
    return this.userStorage.userScopes({ filter, page }).pipe(map((res) => res.data.userScopes));
  }

  addUserScopes(input: AddUserScopesInput): Observable<AddUserScopesResponse> {
    return this.userStorage.addUserScopes({ input }).pipe(map((res) => res.data!.addUserScopes));
  }

  removeUserScopes(input: RemoveUserScopesInput): Observable<RemoveUserScopesResponse> {
    return this.userStorage.removeUserScopes({ input }).pipe(map((res) => res.data!.removeUserScopes));
  }
}
