import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompleteModule } from '@app/shared/component/autocomplete/autocomplete.module';
import { ButtonModule } from '@app/shared/component/button/button.module';
import { DropdownMenuModule } from '@app/shared/component/dropdown-menu/dropdown-menu.module';
import { FilterSelectWithCompareModule } from '@app/shared/component/filter-select-with-compare/filter-select-with-compare.module';
import { FormModule } from '@app/shared/component/form/form.module';
import { FormGroupModule } from '@app/shared/component/form-group/form-group.module';
import { InputModule } from '@app/shared/component/input/input.module';
import { MenuModule } from '@app/shared/component/menu/menu.module';
import { AddUsersRoutingModule } from '@app/shared/component/open-access/add-users/add-users-routing.module';
import { SvgIconModule } from '@app/shared/component/svg-icon/svg-icon.module';
import { TableTitleModule } from '@app/shared/component/table-title/table-title.module';

import { AddUsersComponent } from './add-users.component';

@NgModule({
  declarations: [AddUsersComponent],
  imports: [
    CommonModule,
    AddUsersRoutingModule,
    FormModule,
    InputModule,
    FilterSelectWithCompareModule,
    AutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    FormGroupModule,
    TableTitleModule,
    MenuModule,
    DropdownMenuModule,
    SvgIconModule,
  ],
  exports: [AddUsersComponent],
})
export class AddUsersModule {}
