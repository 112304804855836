import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import { TableSidebarLayoutService } from '@app/shared/component/table-sidenav-layout/service/table-sidebar-layout.service';
import { OpenAccessService } from '@app/shared/service/open-access/open-access.service';
import { Tab } from '@app/typings/tab';
import { AccessRoute } from '@constants/access';
import { BreakpointObserverService, DestroyService } from '@core/service';
import { SessionStorage } from '@services/api';
import { RedirectService, SidenavService } from '@services/shared';
import { NavDrawerItem } from '@typings';

@Component({
  selector: 'nm-open-access',
  templateUrl: './open-access.component.html',
  styleUrls: ['./open-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class OpenAccessComponent implements OnInit {
  isTopBarItem$: Observable<boolean>;
  selectedIndex$ = new BehaviorSubject<number>(0);
  sidenavMode = false;

  tabs: Tab[] = [
    {
      id: 'add-users',
      label: 'Добавить пользователя',
      disabled: false,
    },
  ];

  constructor(
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    public bos: BreakpointObserverService,
    private router: Router,
    private sidenavService: SidenavService,
    public tableSidebarService: TableSidebarLayoutService,
    private redirectService: RedirectService,
    private sessionStorage: SessionStorage,
    public accessService: OpenAccessService,
  ) {}

  ngOnInit(): void {
    this.accessService.rows$.next([]);
    if (!this.accessService.scope$.getValue()) {
      const url = this.router.url.split('/');
      const indexOpenAccess = url.indexOf(AccessRoute);
      const page = url[indexOpenAccess - 1];
      const items = this.sidenavService
        .getNavItems()
        .flatMap((items) => items.routes)
        .filter((item) => !!item && item.access);

      const navItem = items.find((item) => item!.route === page)!;
      if (navItem) {
        this.accessService.scope$.next(navItem.access as string);
      }
    }

    this.tableSidebarService.redirectToInfoPage.pipe(take(1)).subscribe((_) => {
      this.redirectService.redirectToOpenAccess(this.sessionStorage.getOrgId());
    });

    this.tableSidebarService.exitConfirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.tableSidebarService.closeTableSidenav();
    });

    this.tableSidebarService.sidebarComponentData.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((data) => {
      this.accessService.initForm();
      if (data) {
        this.sidenavMode = true;
      } else {
        this.sidenavMode = false;
        const barItem: NavDrawerItem = {
          title: 'Открыть доступ',
          iconLeft: 'arrowLeft',
          onLeftClick: () => {
            this.redirectService.redirectToOpenAccess(this.sessionStorage.getOrgId(), true);
          },
        };
        this.setSelectedIndex(this.tabs.findIndex((tab) => this.router.url.includes(tab.id)));

        this.isTopBarItem$ = this.bos.isMobile$.pipe(
          map((_) => {
            this.sidenavService.setTopBarItem(barItem);
            return true;
          }),
        );
      }
    });
  }

  setSelectedIndex(index: number): void {
    this.selectedIndex$.next(index);
  }

  navigateToTab(index: number): void {
    if (this.sidenavMode) {
      this.setSelectedIndex(index);
    } else {
      const { url } = this.router;
      const newUrl = url.split('/');
      newUrl[newUrl.length - 1] = this.tabs[index].id;

      this.router.navigateByUrl(newUrl.join('/')).then(() => {
        this.setSelectedIndex(index);
      });
    }
  }
}
