import { AutocompleteOption } from '@app/shared/component/autocomplete/autocomplete.model';
import { StrictDictionary } from '@typings';

export const AccessRoute = 'open-access';

export const AccessRoutes = [
  {
    title: '',
    route: AccessRoute,
  },
];

export const ACCESS_TYPE: StrictDictionary<string> = {
  // ALL: 'ALL',
  READER: 'READER',
};

export const ACCESS_TYPE_STRING: StrictDictionary<string> = {
  // ALL: 'Полный доступ',
  READER: 'Только просмотр',
};

const ACCESS_TYPE_KEYS = Object.keys(ACCESS_TYPE);

export const ACCESS_TYPE_LIST: AutocompleteOption<string>[] = ACCESS_TYPE_KEYS.map((type) => ({
  id: type,
  label: ACCESS_TYPE_STRING[type],
  type: 'item',
  data: type,
}));
