<ng-container *ngIf="(show$ | async) || showAlways">
  <div *ngIf="(page$ | async) !== 0 || !!(page$ | async)" class="header" [style.height]="'56px'">
    <nm-skeleton [type]="type"></nm-skeleton>
  </div>
  <div class="template">
    <div class="row" [style.grid-template-columns]="gridTemplateColumns" *ngFor="let row of rowsArray">
      <div class="cell" *ngFor="let column of columnsArray">
        <nm-skeleton [type]="type"></nm-skeleton>
      </div>
    </div>
  </div>
</ng-container>
