import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { fromEvent, of, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { Drop } from '@app/shared/directive/drag-and-drop/drag-and-drop.types';
import { TableService } from '@app/shared/service/table.service';
import { SVG_ICONS_TYPE } from '@constants';
import { TABLE_METRIC_NAME } from '@constants/table';
import { BreakpointObserverService } from '@services/core';

import { BaseComponent } from '../../base-component/base.component';
import { setTemplatesForRowColumns } from '../helper';
import { TableComponent, TemplateColData } from '../table.component';
import { Column, ColumnConfig, Row, ROW_LEVEL_PADDING, SELECT_COLUMN_WIDTH, TABLE_OFFSET_MARGIN, TableConfig } from '../table.types';

import { TableRowService } from './table-row.service';

@Component({
  selector: 'nm-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  providers: [TableService],
})
export class TableRowComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() config: TableConfig;
  @Input() row: Row;
  @Input() columns: Column[];
  @Input() rowPadding = 12;
  @Input() isChild = false;
  @Input() columnsDataTemplates: TemplateColData[] = [];
  @Input() gridTemplateColumns: string = 'auto';
  @Input() isDragging = false;
  @Input() isMouseOverIndex = 0;
  @Input() isMouseOver = false;
  @Input() metric: string = '';
  @Input() compareMetric: string = '';
  @Input() compare: boolean;

  @Output() selectRow = new EventEmitter<Row>();
  @Output() expandRow = new EventEmitter<Row>();
  @Output() dragStart = new EventEmitter<Row>();
  @Output() dragEnd = new EventEmitter<Row>();
  @Output() dropRow = new EventEmitter<Drop<Row>>();
  @Output() dropInside = new EventEmitter<boolean>();

  @ViewChild('rowElement') rowElement: ElementRef;

  dragPreviewIcon: SVG_ICONS_TYPE = 'contentCopy';
  expandColumn = 0;
  selectColWidth = SELECT_COLUMN_WIDTH;
  hovered = false;
  dragModeStart = false;
  tableWidth = 0;
  mouseUp$ = fromEvent(document, 'mouseup');
  dragHovering = false;
  hovering = false;

  constructor(
    public table: TableComponent,
    public breakpointObserverService: BreakpointObserverService,
    public bos: BreakpointObserverService,
    private cdRef: ChangeDetectorRef,
    private tableRowService: TableRowService,
  ) {
    super();
    this.mouseUp$.pipe(takeUntil(this.onDestroy)).subscribe((_) => {
      this.hovered = false;
      this.dragModeStart = false;
    });
  }
  @HostListener('mouseup')
  onMouseUp() {
    this.hovered = false;
    this.dragModeStart = false;
  }

  onMouseDown() {
    this.dragModeStart = true;
  }
  onDragOver() {
    if (this.row?.isCategoryRow) {
      this.dragHovering = true;
    }
  }
  onDragLeave() {
    this.dragHovering = false;
  }
  rowClick(id: string) {
    this.tableRowService.rowClicked.next(id);
  }
  ngOnInit(): void {
    if (this.row.expanded && this.row.lazyChildren) {
      this.loadChildren();
    }
    this.rowPadding = this.rowPadding + (this.isChild ? ROW_LEVEL_PADDING : 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['row']) {
      if (!this.row.selected && this.row.children?.length) this.row = this.updateRowSelectedState(this.row);
    }
  }
  getTableWidth(): number {
    return this.table.elRef.nativeElement.firstChild.offsetWidth - TABLE_OFFSET_MARGIN;
  }

  public loadChildren() {
    this.row.refreshChildren$ = new ReplaySubject<boolean>();
    this.row.refreshChildren$.next(true);

    this.row.refreshChildren$.pipe(switchMap(() => this.row.lazyChildren || of([]))).subscribe((rows) => {
      let loadedRows = setTemplatesForRowColumns(rows, this.columnsDataTemplates);
      if (this.row.selected) {
        loadedRows = rows.map((r: Row) => {
          r.selected = true;
          return r;
        });
      }
      this.row.children = loadedRows;
      this.expandRow.emit(this.row);
    });
  }

  contentIsDefined(column: Column): boolean {
    return typeof column.content !== 'undefined';
  }

  onExpandClick() {
    event?.stopPropagation();
    this.row.expanded = !this.row.expanded;
    if (this.row.lazyChildren && !this.row?.children?.length) {
      this.loadChildren();
    } else {
      this.expandRow.emit(this.row);
    }
  }

  onSelectRowChange(change: MatCheckboxChange) {
    this.row.selected = change.checked;
    this.selectRow.emit(this.row);
  }

  onRowSelect(checked: boolean) {
    this.row.selected = checked;
    this.selectRow.emit(this.row);
  }

  onDropRow(event: Drop<unknown>) {
    this.dropRow.emit(event as Drop<Row>);
    this.dragModeStart = false;
  }

  onDragStart() {
    this.dragStart.emit(this.row);
  }

  onDragEnd() {
    this.dragEnd.emit();
  }
  checkboxClick() {
    event?.stopPropagation();
  }
  updateRowSelectedState(row: Row): Row {
    if (row?.children) {
      row.children = row.children.map((r) => this.updateRowSelectedState(r));
      const hasSelectedChild = row.children.some((ch) => ch.selected);
      const hasDeselectedChild = row.children.some((ch) => !ch.selected);
      const hasIndeterminateChild = row.children?.some((ch) => ch.selected === 'indeterminate');

      if ((hasDeselectedChild && hasSelectedChild) || hasIndeterminateChild) {
        row.selected = 'indeterminate';
      } else if (hasSelectedChild && !hasDeselectedChild) {
        row.selected = true;
      }
      return row;
    }
    return row;
  }

  getLeftForPinned(config: ColumnConfig[], index: number) {
    let left = 0;
    for (let j = 0; j < index; j++) {
      if (config[j].pinned) {
        const a: string | [string, string] = config[j].width!;
        const width = Array.isArray(a) ? a[0].slice(0, -2) : 0;

        left += Number(width);
      }
    }

    return 40 + left + 'px';
  }

  checkBorder(index: number, isLeft: boolean = true) {
    return isLeft
      ? this.config.columnsConfig[index]?.group &&
          (!this.config.columnsConfig[index - 1]?.group ||
            this.config.columnsConfig[index]?.group !== this.config.columnsConfig[index - 1]?.group)
      : this.config.columnsConfig[index]?.group && !this.config.columnsConfig[index + 1]?.group;
  }

  getMainColumn() {
    const column = this.config.columnsConfig.find((col) => col.required);
    return column ? this.config.columnsConfig.indexOf(column) : 0;
  }

  getMetricColumn(metric: string) {
    const column = this.config.columnsConfig.find((col) => col.metricName === metric);
    return column ? this.config.columnsConfig.indexOf(column) : 1;
  }

  protected readonly TABLE_METRIC_NAME = TABLE_METRIC_NAME;
}
