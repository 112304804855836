import { gql } from 'apollo-angular';

import { fragmentChartOutput } from '@app/api/queries/analytics';

export const queryProductsReport = gql`
  query productsReport($input: ProductsReportInput!, $pageRequestInput: PageRequestInput, $sort: [ProductReportSort!]) {
    productsReport(input: $input) {
      total {
        revenue
        prime
        discount
        profit
        sales
      }
      rows(pageRequest: $pageRequestInput, sort: $sort) {
        content {
          productName
          productType
          productId
          categories {
            id
            name
          }
          sales
          revenue
          prime
          discount
          profit
          unitType
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryProductsReportV2 = gql`
  query productsReportV2($input: ReportV2Input!, $pageRequestInput: PageRequestInput, $sort: [ProductReportSort!]) {
    productsReportV2(input: $input) {
      total {
        revenue
        prime
        discount
        profit
        sales
      }
      rows(pageRequest: $pageRequestInput, sort: $sort) {
        content {
          productName
          productType
          productId
          categories {
            id
            name
          }
          sales
          revenue
          prime
          discount
          profit
          unitType
        }
        pageNumber
        pageSize
        total
        totalPages
      }
    }
  }
`;

export const queryReportFilters = gql`
  query reportFilters($report: ReportName) {
    reportFilters(report: $report) {
      filters {
        field
        searchOperations
      }
    }
  }
`;

export const queryProductsReportChart = gql`
  query productsReportChart($grouping: DateGrouping!, $input: ProductsReportInput!, $seriesIds: [UUID!]!) {
    productsReportChart(grouping: $grouping, input: $input, seriesIds: $seriesIds) {
      ...ChartOutput
    }
  }
  ${fragmentChartOutput}
`;
