import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { BaseLayoutService } from '@app/layout/base-layout/base-layout.service';
import { OrganizationStorage } from '@app/modules/settings/services/organization/organization.storage';
import { BaseComponent } from '@app/shared/component/base-component/base.component';
import { BreadcrumbService } from '@core/service/breadcrumb.service';
import { SessionStorage } from '@services/api';
import { AuthService, InvitationStorage } from '@services/auth';
import { CatalogService, CatalogStorage } from '@services/catalog';
import { AccountStorage } from '@services/core';
import { InfiniteLoaderService, RedirectService } from '@services/shared';
import { OrganizationSite } from '@typings';

@Component({
  selector: 'nm-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent extends BaseComponent implements OnInit {
  infiniteLoaderService = new InfiniteLoaderService<OrganizationSite>();
  isEmpty = true;

  accountId = this.accountStorage.getAccountId();
  organizations: OrganizationSite[] = [];

  organizationsLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private catalogStorage: CatalogStorage,
    private invitationStorage: InvitationStorage,
    private accountStorage: AccountStorage,
    private sessionStorage: SessionStorage,
    private layoutService: BaseLayoutService,
    private authService: AuthService,
    private organizationStorage: OrganizationStorage,
    private catalogService: CatalogService,
    private redirectService: RedirectService,
  ) {
    super();

    this.layoutService.setHeaderLeftIcon('logout');
    this.layoutService.setLayoutSettings({
      showHeader: true,
      showFooterInfo: false,
      showLogo: false,
      title: 'Выбор организации',
    });
    this.layoutService.iconClick$.subscribe((_) => {
      this.logout();
    });

    this.accountStorage.organizations
      .pipe(
        take(1),
        filter((result) => !!result),
      )
      .subscribe((organizations) => {
        if (organizations) {
          this.accountStorage.accountFetched.next(true);
          if (organizations.length === 0) {
            this.accountStorage.createOrganization();
          }

          if (organizations.length === 1) {
            this.setCurrentOrganization(organizations[0]);
          }
        }
      });

    this.infiniteLoaderService.init({
      loadItemsFn: (pageRequestInput) => {
        return this.organizationStorage
          .getOrganizationSitesByAccount({
            accountId: this.accountId,
            filter: {
              search: undefined,
            },
            pageRequestInput,
          })
          .pipe(
            map((data) => {
              this.organizationsLoading$.next(false);
              this.accountStorage.accountFetched.next(true);
              this.accountStorage.setOrganizations(data.data.organizationSitesByAccount.content);

              return {
                total: data.data.organizationSitesByAccount.total,
                totalPages: data.data.organizationSitesByAccount.totalPages,
                items: data.data.organizationSitesByAccount.content,
              };
            }),
          );
      },
      itemIdFn: (item: OrganizationSite) => item.id,
    });

    this.onLoadMore();
  }

  logout(): void {
    this.authService.logout();
    this.sessionStorage.clear();
  }

  ngOnInit(): void {
    this.breadcrumbService.set([
      {
        name: 'Выбор организации',
        link: 'auth/organizations',
      },
    ]);
  }

  onLoadMore(): void {
    this.infiniteLoaderService.loadItems();
  }

  switchOrganization(organization: OrganizationSite): void {
    this.selectOrganizationQuery();

    this.setCurrentOrganization(organization);
  }

  selectOrganizationQuery() {
    this.accountStorage.organization
      .pipe(
        takeUntil(this.onDestroy),
        filter((result) => !!result),
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        switchMap((organization) =>
          this.catalogStorage.getCatalogByOrganization().pipe(
            take(1),
            map((res) => ({ catalog: res.data.catalogByOrganization, organization })),
          ),
        ),
      )
      .subscribe(({ organization, catalog }) => {
        const { id } = organization || {};

        if (id) {
          if (catalog && this.catalogService.isAccess(catalog)) {
            return this.accountStorage.redirect();
          } else {
            return this.redirectService.redirectToCatalog(id);
          }
        }
      });
  }

  setCurrentOrganization(organization: OrganizationSite): void {
    this.sessionStorage.setCurrencyUnit(organization?.currency || 'RUB');
    this.sessionStorage.setEndWorkTime(organization.endWorkTime);
    this.accountStorage.setOrganization(organization);
    this.accountStorage.setActiveOrganization(organization);
  }
}
