import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FooterModule } from '@app/shared/component/footer/footer.module';
import { AddUsersModule } from '@app/shared/component/open-access/add-users/add-users.module';
import { OpenAccessComponent } from '@app/shared/component/open-access/open-access.component';
import { OpenAccessRoutingModule } from '@app/shared/component/open-access/open-access-routing.module';
import { SectionModule } from '@app/shared/component/section/section.module';
import { TabModule } from '@app/shared/component/tab/tab.module';
import { TabGroupModule } from '@app/shared/component/tab-group/tab-group.module';

@NgModule({
  declarations: [OpenAccessComponent],
  imports: [CommonModule, OpenAccessRoutingModule, AddUsersModule, TabGroupModule, FooterModule, TabModule, SectionModule, RouterOutlet],
  exports: [OpenAccessComponent],
})
export class OpenAccessModule {}
