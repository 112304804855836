import { NavDrawerItem } from '@typings';

export enum SalesRoute {
  ordersReport = 'orders-report',
  bookingReport = 'booking-report',
  shifts = 'shifts',
}

export enum SalesRouteName {
  ordersReport = 'Заказы',
  bookingReport = 'Бронирования',
  shifts = 'Кассовые смены',
}

export enum SalesAccess {
  ordersReport = 'sales.ordersList',
  bookingReport = 'sales.bookingsList',
  shifts = 'sales.shiftsList',
}

export enum SalesRouteParam {
  orderId = 'idOrder',
  shiftId = 'idShift',
}

export const SALES_ITEM_ORDERS: NavDrawerItem = {
  title: SalesRouteName.ordersReport,
  route: SalesRoute.ordersReport,
  access: SalesAccess.ordersReport,
  routes: [
    {
      title: '',
      route: SalesRouteParam.orderId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_ITEM_BOOKING: NavDrawerItem = {
  title: SalesRouteName.bookingReport,
  route: SalesRoute.bookingReport,
  access: SalesAccess.bookingReport,
};

export const SALES_ITEM_SHIFTS: NavDrawerItem = {
  title: SalesRouteName.shifts,
  route: SalesRoute.shifts,
  access: SalesAccess.shifts,
  routes: [
    {
      title: '',
      route: SalesRouteParam.shiftId,
      keepUrlParams: true,
    },
  ],
};

export const SALES_CHILD_ITEMS: NavDrawerItem[] = [SALES_ITEM_ORDERS, SALES_ITEM_BOOKING, SALES_ITEM_SHIFTS];
