import { ValueColor } from '@typings';

export type MetricIcon = 'dot' | 'up' | 'down';

type Metric = {
  id: string;
  title: string;
  value: string;
  secondaryValue: string;
  secondaryValueIcon: MetricIcon;
  secondaryValueColor: ValueColor;
  firstCompareValue: string;
  secondCompareValue: string;
  percentV2: boolean;
  description: string;
  helpText: string;
  testId: string;
};

export type MetricModel = Partial<Metric>;

export const METRIC_DEFAULTS: Metric = {
  id: '',
  title: '',
  value: '',
  secondaryValue: '',
  secondaryValueIcon: 'dot',
  secondaryValueColor: 'plain',
  firstCompareValue: '',
  secondCompareValue: '',
  percentV2: false,
  description: '',
  helpText: '',
  testId: '',
};

export function getOrDefault<K extends keyof MetricModel = keyof MetricModel>(item: MetricModel, field: K): MetricModel[K] {
  if (field in item && item[field] !== undefined) {
    return item[field];
  }

  return METRIC_DEFAULTS[field];
}
