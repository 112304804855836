import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { ButtonModule } from '../button/button.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MenuModule } from '../menu/menu.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, ButtonModule, IconButtonModule, MenuModule, MatMenuModule, SvgIconModule],
  exports: [FooterComponent],
})
export class FooterModule {}
