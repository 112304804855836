import { Injectable } from '@angular/core';

import { UserApi } from '@app/modules/employees/services/employees/user.api';
import {
  MutationAddUserRolesArgs,
  MutationAddUserScopesArgs,
  MutationAddUserStoreRolesArgs,
  MutationDeleteUserArgs,
  MutationInviteUserArgs,
  MutationRefreshUserPinArgs,
  MutationRemoveUserRolesArgs,
  MutationRemoveUserScopesArgs,
  MutationRemoveUserStoreRolesArgs,
  MutationUpdateUserArgs,
  QueryIamUserArgs,
  QueryUserArgs,
  QueryUsersArgs,
  QueryUserScopesArgs,
} from '@app/typings/user';
import { NotifyService } from '@services/shared';
import { MutationResult, QueryResult } from '@typings';
@Injectable({
  providedIn: 'root',
})
export class UserStorage {
  constructor(private notifyService: NotifyService, private userApi: UserApi) {}

  users(variables: QueryUsersArgs): QueryResult<'users'> {
    return this.userApi.users(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка сотрудников',
      }),
    );
  }

  user(variables: QueryUserArgs): QueryResult<'user'> {
    return this.userApi.user(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка сотрудников',
      }),
    );
  }

  iamUser(variables: QueryIamUserArgs): QueryResult<'iamUser'> {
    return this.userApi.iamUser(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка сотрудников',
      }),
    );
  }

  inviteUser(variables: MutationInviteUserArgs): MutationResult<'inviteUser'> {
    return this.userApi.inviteUser(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при создании профиля',
      }),
    );
  }

  updateUser(variables: MutationUpdateUserArgs): MutationResult<'updateUser'> {
    return this.userApi.updateUser(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  deleteUser(variables: MutationDeleteUserArgs): MutationResult<'deleteUser'> {
    return this.userApi.deleteUser(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при удалении сотрудника',
        success: 'Сотрудник успешно удалён',
      }),
    );
  }

  addUserRoles(variables: MutationAddUserRolesArgs): MutationResult<'addUserRoles'> {
    return this.userApi.addUserRoles(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  removeUserRoles(variables: MutationRemoveUserRolesArgs): MutationResult<'removeUserRoles'> {
    return this.userApi.removeUserRoles(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  addUserStoreRoles(variables: MutationAddUserStoreRolesArgs): MutationResult<'addUserStoreRoles'> {
    return this.userApi.addUserStoreRoles(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  removeUserStoreRoles(variables: MutationRemoveUserStoreRolesArgs): MutationResult<'removeUserStoreRoles'> {
    return this.userApi.removeUserStoreRoles(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении сотрудника',
      }),
    );
  }

  changeUserPin(variables: MutationRefreshUserPinArgs): MutationResult<'refreshUserPin'> {
    return this.userApi.changeUserPin(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении пин-кода',
      }),
    );
  }

  userScopes(variables: QueryUserScopesArgs): QueryResult<'userScopes'> {
    return this.userApi.userScopes(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при получении списка сотрудников',
      }),
    );
  }

  addUserScopes(variables: MutationAddUserScopesArgs): MutationResult<'addUserScopes'> {
    return this.userApi.addUserScopes(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении доступа',
      }),
    );
  }

  removeUserScopes(variables: MutationRemoveUserScopesArgs): MutationResult<'removeUserScopes'> {
    return this.userApi.removeUserScopes(variables).pipe(
      this.notifyService.$notify({
        error: 'Ошибка при обновлении доступа',
      }),
    );
  }
}
