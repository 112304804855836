import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';

import { getDateGroupingForGraph } from '@app/shared/component/filters-table/filters-table.utils';
import { GRAPH_GROUPING, GRAPH_HEIGHT, GRAPH_HEIGHT_STRING_SIZE } from '@constants/graph-setting';
import { ParamsService } from '@core/service/params.service';
import { DateGrouping, DayOfWeek } from '@typings';

@Injectable({
  providedIn: 'root',
})
export class GraphSettingsService {
  readonly showLegendParam: string = 'showLegend';
  readonly showLabelParam: string = 'showLabel';
  readonly heightGraphParam: string = 'heightGraph';
  readonly groupingGraphParam: string = 'groupingGraph';
  readonly trendLineParam: string = 'trendLine';

  dateGrouping: DateGrouping = 'DAY';

  showLegend$ = new BehaviorSubject<boolean>(this.paramsService.getParamForBoolean(this.showLegendParam, false));
  showLabel$ = new BehaviorSubject<boolean>(this.paramsService.getParamForBoolean(this.showLabelParam, false));
  heightGraph$ = new BehaviorSubject<string>(this.paramsService.getParamsForString(this.heightGraphParam) || GRAPH_HEIGHT.MIDDLE);
  groupingGraph$ = new BehaviorSubject<string>(this.paramsService.getParamsForString(this.groupingGraphParam) || GRAPH_GROUPING.AUTO);
  trendLine$ = new BehaviorSubject<boolean>(this.paramsService.getParamForBoolean(this.trendLineParam, false));

  constructor(private paramsService: ParamsService) {}

  getGraphSettings() {
    return {
      onShowLegend: () => this.toggleLegend(),
      onShowLabel: () => this.toggleLabel(),
      onSetHeightGraph: (height: string) => this.setHeightGraph(height),
      onSetGroupingGraph: (height: string) => this.setGroupingGraph(height),
      onShowTrendLine: () => this.toggleTrendLine(),
      onDownload: () => this.downloadGraph(),
    };
  }

  toggleLegend() {
    const showLegend: boolean = !this.showLegend$.getValue();
    this.showLegend$.next(showLegend);
    this.paramsService.setParams([{ name: this.showLegendParam, value: showLegend }], true);
  }

  toggleLabel() {
    const showLabel: boolean = !this.showLabel$.getValue();
    this.showLabel$.next(showLabel);
    this.paramsService.setParams([{ name: this.showLabelParam, value: showLabel }], true);
  }

  toggleTrendLine() {
    const trendLine: boolean = !this.trendLine$.getValue();
    this.trendLine$.next(trendLine);
    this.paramsService.setParams([{ name: this.trendLineParam, value: trendLine }], true);
  }

  getHeightGraph(): number {
    return Number(GRAPH_HEIGHT_STRING_SIZE[this.heightGraph$.getValue()].slice(0, 3));
  }

  setHeightGraph(height: string) {
    this.heightGraph$.next(height);
    this.paramsService.setParams([{ name: this.heightGraphParam, value: height }], true);
  }

  setGroupingGraph(grouping: string) {
    this.groupingGraph$.next(grouping);
    this.paramsService.setParams([{ name: this.groupingGraphParam, value: grouping }], true);
  }

  getGroupingGraph(range: DateRange<Date> | null, days?: DayOfWeek[]) {
    this.dateGrouping = getDateGroupingForGraph(this.groupingGraph$.getValue(), range, days);
  }

  downloadGraph() {}
}
