import { CoreSchema, StrictDictionary } from '@app/typings/index';

export type Role = CoreSchema.Role;
export type RoleFilterInput = CoreSchema.RoleFilterInput;
export type RolePage = CoreSchema.RolePage;

export type CreateRoleResponse = CoreSchema.CreateRoleResponse;
export type CreateRoleInput = CoreSchema.CreateRoleInput;
export type UpdateRoleInput = CoreSchema.UpdateRoleInput;
export type UpdateRoleResponse = CoreSchema.UpdateRoleResponse;
export type DeleteRoleResponse = CoreSchema.DeleteRoleResponse;

export type MutationCreateRoleArgs = CoreSchema.MutationCreateRoleArgs;
export type MutationDeleteRoleArgs = CoreSchema.MutationDeleteRoleArgs;
export type MutationUpdateRoleArgs = CoreSchema.MutationUpdateRoleArgs;

export type QueryRoleArgs = CoreSchema.QueryRoleArgs;
export type QueryRolesArgs = CoreSchema.QueryRolesArgs;
export type QueryRolesByUserArgs = CoreSchema.QueryRolesByUserArgs;

export enum ScopeNavigationRoute {
  analytics = 'analytics',
  sales = 'sales',
  catalog = 'catalog',
  marketing = 'marketing',
  warehouse = 'warehouse',
  settings = 'settings',
  profile = 'profile',
  employees = 'employees',
  application = 'application',
}

export const scopeDictionary: StrictDictionary<string, string> = {
  analytics: 'Аналитика',
  sales: 'Продажи',
  catalog: 'Каталог',
  'catalog.read': 'Каталог',
  marketing: 'Маркетинг',
  'marketing.read': 'Маркетинг',
  warehouse: 'Склады',
  'warehouse.read': 'Склады',
  employees: 'Сотрудники',
  'employees.read': 'Сотрудники',
  applications: 'Приложения',
  'applications.read': 'Приложения',
  settings: 'Настройки',
  'settings.read': 'Настройки',
  pos: 'POS',
};

export const fullScopes = ['analytics', 'sales', 'catalog', 'marketing', 'warehouse', 'employees', 'applications', 'settings'];
