import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { METRIC_DEFAULTS, MetricIcon } from '@app/shared/component/metric/metric.model';
import { SVG_ICONS_TYPE } from '@constants';
import { BreakpointObserverService } from '@core/service';
import { ValueColor } from '@typings';

@Component({
  selector: 'nm-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricComponent {
  @Input() title: string = METRIC_DEFAULTS.title;
  @Input() value: string = METRIC_DEFAULTS.value;
  @Input() secondaryValue: string = METRIC_DEFAULTS.secondaryValue;
  @Input() secondaryValueIcon: MetricIcon = METRIC_DEFAULTS.secondaryValueIcon;
  @Input() secondaryValueColor: ValueColor = METRIC_DEFAULTS.secondaryValueColor;
  @Input() firstCompareValue: string = METRIC_DEFAULTS.firstCompareValue;
  @Input() secondCompareValue: string = METRIC_DEFAULTS.secondCompareValue;
  @Input() percentV2: boolean = METRIC_DEFAULTS.percentV2;
  @Input() description: string = METRIC_DEFAULTS.description;
  @Input() helpText: string = METRIC_DEFAULTS.helpText;
  @Input() testId: string = METRIC_DEFAULTS.testId;
  @Input() isLoading: boolean = false;
  @Input() isGrid: boolean = false;

  get secValueIcon(): SVG_ICONS_TYPE | null {
    if (this.secondaryValueIcon === 'dot') {
      return 'checks';
    }
    if (this.secondaryValueIcon === 'up') {
      return 'arrowUp';
    }
    if (this.secondaryValueIcon === 'down') {
      return 'arrowDown';
    }
    return null;
  }

  constructor(public bos: BreakpointObserverService) {}
}
