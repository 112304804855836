import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, mergeMap, skip, skipWhile, takeUntil, throttleTime } from 'rxjs/operators';

import { Drop } from '@app/shared/directive/drag-and-drop/drag-and-drop.types';
import { MoveDirection } from '@app/shared/directive/scroll-visibility/scroll-visibility.directive';
import { SelectInTableService } from '@app/shared/service/select-in-table.service';
import { TableService } from '@app/shared/service/table.service';
import { AccountStorage, BreakpointObserverService } from '@core/service';
import { FunctionType } from '@typings';
import { tableGridTemplateColumns } from '@utils';

import { BaseComponent } from '../base-component/base.component';

import { TableBodyComponent } from './table-body/table-body.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { TableColumnDirective } from './column-name.directive';
import { createRowId, ID_SPLITTER, PARENT_SCROLL_WRAPPER_ATTR, setTemplatesForRowColumns, sortColumn } from './helper';
import {
  Column,
  ColumnConfig,
  DragAndDropType,
  DropResultEvent,
  EntityWithId,
  Row,
  RowHeightEvent,
  RowVisibilityDirection,
  TableConfig,
  TableInfiniteLoadingState,
} from './table.types';

@Component({
  selector: 'nm-custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends BaseComponent implements AfterViewInit {
  @Input() set config(conf: TableConfig) {
    conf.mobileVersion = conf.mobileVersion && this.accountStorage.mobileVersion$.getValue();
    conf.itemVersion = conf.itemVersion && this.accountStorage.mobileVersion$.getValue();
    this.#config = conf;
    this.tableService.gridTemplateColumns$.next(
      this.bos.getIsMobile()
        ? conf.mobileVersion
          ? this.mobileGridTemplateColumns
          : conf.itemVersion
          ? this.itemGridTemplateColumns
          : this.getGridTemplateColumns()
        : this.getGridTemplateColumns(),
    );

    const config = { ...this.config };
    config.columnsConfig = this.getGroupHeaderConfig();
    this.headerConfig.next(config);

    if (this.#config.usableWidth) {
      this.usableWidth = this.#config.usableWidth;
    }
  }
  get config(): TableConfig {
    return this.#config;
  }
  @Input() set rows(rows: Row[]) {
    rows = this.prepareColumns(rows);
    this.rows$.next(rows);
  }
  refresh$ = new BehaviorSubject<boolean>(true);
  headerConfig = new BehaviorSubject<TableConfig | null>(null);

  @Input() infiniteLoadingState: TableInfiniteLoadingState = {
    reloading: false,
    canLoad: false,
    canLoadUp: false,
    loadingUp: false,
    loadingDown: false,
    offsetBottom: 0,
    offsetTop: 0,
  };

  @Input() scrollToItem: Observable<string>;
  @Input() isWidgetTable: boolean = false;
  @Input() testId: string;
  @Input() metric: string;
  @Input() compareMetric: string;
  @Input() compare: boolean;

  isDragging = false;
  bodyElement: HTMLElement = document.body;
  usableWidth: string = '100%';
  mobileGridTemplateColumns = 'minmax(125px,50%) minmax(200px,50%)';
  itemGridTemplateColumns = 'minmax(220px,100%) 64px';

  @Output() headerClick = new EventEmitter<ColumnConfig>();
  @Output() expandRow = new EventEmitter<Row>();
  @Output() dragStart = new EventEmitter<Row>();
  @Output() dropRow = new EventEmitter<DropResultEvent>();
  @Output() loadMoreItems = new EventEmitter();
  @Output() updateRowsPositions = new EventEmitter<Row[]>();

  get rows(): Row[] {
    return this.rows$.getValue();
  }

  #config: TableConfig;
  private rows$ = new BehaviorSubject<Row[]>([]);
  viewRows$ = this.rows$.pipe(
    mergeMap((rows) => {
      return this.columnsDataTemplates$.pipe(
        map((columnsDataTemplates) => {
          rows = setTemplatesForRowColumns(rows, columnsDataTemplates);
          return rows;
        }),
      );
    }),
  );

  tableHeader: TableHeaderComponent;
  tableBody: TableBodyComponent;
  set columnsDataTemplates(columnsDataTemplates: TemplateColData[]) {
    this.columnsDataTemplates$.next(columnsDataTemplates);
  }
  get columnsDataTemplates() {
    return this.columnsDataTemplates$.getValue();
  }
  columnsDataTemplates$ = new BehaviorSubject<TemplateColData[]>([]);
  selected: Row[] = [];
  selectedRows$: Observable<Row[]> = combineLatest([this.rows$, this.refresh$]).pipe(
    map(([rows, _]) => {
      const allRows = this.getFlatRowList(rows);
      const selectedRows = this.selectService.getSelected();
      const hasSelectedRows = allRows.some((row) => row.selected);
      const hasDeselectedRows = allRows.some((row) => !row.selected);
      if (this.tableHeader && hasSelectedRows && hasDeselectedRows) {
        this.tableHeader.headerChecked = 'indeterminate';
      }

      return selectedRows.filter((r) => {
        return !!allRows.find((row) => row?.rowDataId === r?.rowDataId)?.selected;
      });
    }),
  );

  isAnyRowsSelected$: Observable<boolean> = this.selectedRows$.pipe(map((rows) => rows.length > 0));
  isOneRowSelected$: Observable<boolean> = this.selectedRows$.pipe(map((rows) => rows.length === 1));
  dragRow$: Subject<Drop<Row>> = new Subject();
  dragRowObservable$ = this.dragRow$.pipe(skip(1), throttleTime(2000));

  isLoadUpScrolled: boolean = false;
  keepScrollToRow: string | undefined;
  @ViewChild('tableHeader') set component(component: TableHeaderComponent) {
    this.tableHeader = component;
  }

  @ViewChild('tableBody') set tableBodyComponent(component: TableBodyComponent) {
    this.tableBody = component;
  }

  @ViewChild('tableBody', { read: ElementRef }) tableBodyEl: ElementRef<HTMLElement>;
  @ViewChild('tableWrapper', { read: ElementRef }) tableWrapper: ElementRef<HTMLElement>;
  @ViewChild('bottomSpinner', { read: ElementRef, static: false }) bottomSpinner: ElementRef<HTMLElement>;
  @ViewChild('topSpinner', { read: ElementRef, static: false }) topSpinner: ElementRef<HTMLElement>;

  @ContentChildren(TableColumnDirective, { read: TemplateRef<ElementRef> }) colsTemplates: QueryList<TemplateRef<ElementRef>>;
  @ContentChildren(TableColumnDirective) colsColumnData: QueryList<TableColumnDirective>;

  constructor(
    public elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    public selectService: SelectInTableService,
    public tableService: TableService,
    public bos: BreakpointObserverService,
    public accountStorage: AccountStorage,
  ) {
    super();
    // this.dragRowObservable$.pipe(takeUntil(this.onDestroy)).subscribe((_) => {
    //   if (this.tableBody.dropList.lastDragEnterItem) {
    //     const row = this.getFlatRowList(this.rows).find((r) => r?.rowId === (this.tableBody.dropList.lastDragEnterItem.data as Row)?.rowId);
    //     if (row) {
    //       row.expanded = true;
    //       this.onRowExpand(row);
    //       this.cdr.markForCheck();
    //     }
    //   }
    // });
  }
  ngAfterViewInit() {
    this.subscribeToScroll();
    this.config.columnsConfig.forEach((col) => {
      if (col.defaultSort) {
        if (col.sortable) {
          col.sorting = col.defaultSort;
          this.headerClick.emit(col);
        }
      }
    });

    this.columnsDataTemplates = this.prepareColumnsTemplates(this.colsTemplates.toArray(), this.colsColumnData.toArray());
    this.colsColumnData.changes.subscribe((colsTemplates) => {
      this.columnsDataTemplates = this.prepareColumnsTemplates(colsTemplates.toArray(), this.colsColumnData.toArray());
      this.cdr.detectChanges();
    });
    this.colsTemplates.changes.subscribe((cols) => {
      this.columnsDataTemplates = this.prepareColumnsTemplates(this.colsTemplates.toArray(), cols.toArray());
      this.cdr.detectChanges();
    });
    this.selectService.tableInited.next();
    this.cdr.detectChanges();

    this.bos.isMobile$.subscribe((isMobile) => {
      const columns = this.prepareColumnsTemplates(this.colsTemplates.toArray(), this.colsColumnData.toArray());
      this.columnsDataTemplates =
        isMobile && this.config.mobileVersion ? [columns[this.getMainColumn()], columns[this.getMetricColumn(this.metric)]] : columns;
      this.cdr.detectChanges();

      this.tableService.gridTemplateColumns$.next(
        isMobile
          ? this.config.mobileVersion
            ? this.mobileGridTemplateColumns
            : this.config.itemVersion
            ? this.itemGridTemplateColumns
            : this.getGridTemplateColumns()
          : this.getGridTemplateColumns(),
      );
    });
  }

  subscribeToScroll(): void {
    if (this.infiniteLoadingState && this.scrollToItem) {
      this.scrollToItem
        .pipe(
          skipWhile((id) => !id),
          takeUntil(this.onDestroy),
        )
        .subscribe((itemId) => {
          if (itemId === 'top') {
            this.scrollToTopSpinner();
          } else if (itemId === 'bottom') {
            this.scrollToBottomSpinner();
          } else {
            this.tableBody.scrollToRow(itemId);
          }
        });
    }
  }

  prepareColumnsTemplates(templates: TemplateRef<ElementRef>[], data: TableColumnDirective[]): TemplateColData[] {
    return templates.map((t, i) => {
      return {
        template: t,
        columnName: data[i]?.name,
      };
    });
  }

  prepareColumns(rows: Row[]): Row[] {
    let resultRows: Row[] = rows;
    this.config.columnsConfig = this.config.columnsConfig.sort((a, b) => (a?.order || 0) - (b?.order || 0));
    const sortedColumnsNames = this.config.columnsConfig.map((c) => c.name);

    resultRows = this.sortColumns(resultRows, sortedColumnsNames);

    resultRows = this.setIndexes(resultRows);

    if (this.config?.enumerable?.enabled) {
      resultRows = this.setEnumerable(resultRows, this.config.enumerable.children);
    }
    return resultRows;
  }

  sortColumns(rows: Row[], colsOrder: string[]): Row[] {
    const emptyState = rows[0]?.rowDataId === 'empty';

    if (!rows?.length) {
      return [];
    }
    if (emptyState) {
      return rows;
    }
    return rows.map((row) => {
      if (row.children) {
        row.children = this.sortColumns(row.children, colsOrder);
      }
      row.columnsData = row.columnsData;
      let sortedColumns: Column[] = [];
      colsOrder.forEach((col) => {
        const curColumn = row.columnsData.find((c) => c.column === col);
        if (curColumn) {
          sortedColumns.push(curColumn);
        }
      });
      row.columnsData = sortedColumns;
      return row;
    });
  }

  setIndexes(rows: Row[], parentId?: string): Row[] {
    return rows.map((row, i) => {
      const curRowId = i + '';
      if (parentId) {
        row.rowId = createRowId(parentId, curRowId);
      } else if (row?.rowId === undefined || row?.rowId === null) {
        row.rowId = curRowId;
        i++;
      }
      if (row.children) {
        row.children = this.setIndexes(row.children, row.rowId);
      }
      return row;
    });
  }

  setEnumerable(rows: Row[], enableChilds = false): Row[] {
    let enumerator = 0;

    function setRowEnumerator(row: Row): void {
      if (!row?.skipEnumerator && row.rowId !== 'total') {
        row.enumerator = enumerator;
        enumerator++;
      }

      if (enableChilds && row.children && row.expanded) {
        for (const child of row.children) {
          setRowEnumerator(child);
        }
      }
    }

    for (const row of rows) {
      setRowEnumerator(row);
    }

    return rows;
  }

  onClickHeader(column: ColumnConfig) {
    if (column.sortable) {
      sortColumn(column);
      this.headerClick.emit(column);
    }
  }

  onChangeSelection(event: boolean) {
    const allRows = this.getFlatRowList(this.rows);
    this.switchSelectRow(this.rows, event);
    const selected: Row[] = allRows.filter((r) => r.rowId !== 'total').filter((r) => r.selected === true);
    const currentlySelected = this.selectService.getSelected();

    if (event) {
      this.selectService.setSelected([...currentlySelected, ...selected]);
    } else {
      this.selectService.setSelected([]);
      // this.selectService.setSelected(currentlySelected.filter((row) => !allRows.some((r) => r.rowDataId === row.rowDataId)));
    }

    this.refresh$.next(true);
  }

  switchSelectRow(rows: Row[], select?: boolean): Row[] {
    return rows.map((r) => {
      if (r?.rowDataId === 'empty') {
        return r;
      }
      r.selected = select ?? !r.selected;
      this.onRowSelect(r, false);
      return r;
    });
  }

  getGridTemplateColumns(): string {
    return tableGridTemplateColumns(this?.config);
  }

  getGroupHeaderConfig() {
    let columnsConfig: ColumnConfig[] = [];

    this.config.columnsConfig.forEach((c, index) => {
      if (c.group) {
        if (this.config.columnsConfig[index + 1]?.group && c.group === this.config.columnsConfig[index + 1]?.group) {
        } else {
          if (c.group !== this.config.columnsConfig[index + 1]?.group || !this.config.columnsConfig[index + 1]?.group) {
            columnsConfig.push(this.getGroupHeaderColumn(c.group));
          }
        }
      } else {
        const column: ColumnConfig = {
          sortable: false,
          width: c.width,
          title: '',
          name: c.name,
          columnColor: c.columnColor,
          hidden: c.hidden,
        };
        columnsConfig.push(column);
      }
    });

    return columnsConfig;
  }

  getGroupHeaderColumn(id: string): ColumnConfig {
    const groupHeader = this.config.groupHeader;
    const group = groupHeader?.find((g) => g.id === id);

    return {
      name: group?.id || '',
      title: group?.title || '',
      sortable: false,
      group: group?.id,
      columnColor: group?.columnColor,
    };
  }

  onRowSelect(row: Row, update = true) {
    if (row?.rowDataId === 'empty') {
      return;
    }

    let updatedRows: Row[] = [...this.rows];
    if (row.children) {
      updatedRows = this.switchChildrenRow(updatedRows, row);
    }

    const splittedId = row?.rowId?.split(ID_SPLITTER);
    while (splittedId && splittedId.length > 1) {
      const parentIds = splittedId?.slice(0, -1).join(ID_SPLITTER);

      if (parentIds) {
        updatedRows = this.switchParents(this.rows, row, parentIds as string);
      }
      splittedId.pop();
    }
    const flatRowList = this.getFlatRowList(updatedRows);
    const selectedRows: Row[] = flatRowList.filter((r) => r.selected === true);
    if (update) {
      const selected = this.selectService.getSelected().filter((r) => !flatRowList.some((row) => row.rowDataId === r.rowDataId));

      this.selectService.setSelected([...selected, ...selectedRows]);
    }
    this.rows = updatedRows;

    if (!row?.selected) {
      if (selectedRows.length) {
        this.tableHeader.headerChecked = 'indeterminate';
      } else {
        this.tableHeader.headerChecked = false;
      }

      return;
    }

    if (row?.selected && selectedRows.length === flatRowList.length) {
      this.tableHeader.headerChecked = true;
    }

    if (row?.selected && selectedRows.length !== flatRowList.length) {
      this.tableHeader.headerChecked = 'indeterminate';
    }
  }

  onDragStart(row: Row) {
    this.isDragging = true;
    this.dragStart.emit(row);
    this.bodyElement.style.cursor = 'grabbing';
  }

  onDragEnd() {
    this.bodyElement.style.cursor = 'unset';
    this.isDragging = false;
  }

  onDropRow(event: Drop<Row>) {
    if (event.newPosition === event.prevPosition) {
      return;
    }
    let pointerPosition = -1;
    const onPositionOf = this.tableBody.dropList.dragAndDropListService.items$.getValue()[event?.newPosition || 0];

    let currentRowOnPosition = onPositionOf?.data as Row<EntityWithId, unknown>;

    if (event?.data?.children?.length && this.isRowParrentOfRow(event?.data?.children, currentRowOnPosition)) {
      return;
    }
    if (event.placeOnTop && !event.dropInside) {
      const flatRows = this.getExpandedFlatRowList(this.rows$.getValue());
      const index = flatRows.findIndex((row) => row.rowDataId === currentRowOnPosition.rowDataId);
      if (index > 0) {
        // Getting previous element
        currentRowOnPosition = flatRows[index - 1];
        event.placeOnTop = false;
      }
      pointerPosition = index;
    }
    //drop inside category if pointer is at category or at the first element of expanded category
    event.dropInside =
      (event.dropInside || (currentRowOnPosition?.children && currentRowOnPosition.expanded && !event.placeOnTop)) &&
      currentRowOnPosition.isCategoryRow;
    if (this.config?.dragAndDropOptions?.dragAndDropType === DragAndDropType.insideLevel) {
      this.dropInsideLevel(event, currentRowOnPosition);
    } else {
      this.dropLevelToLevel(event, currentRowOnPosition);
    }

    this.dropRow.emit({
      onPositionOf: currentRowOnPosition,
      dropRowEvent: event,
      firstInList: (event?.placeOnTop && pointerPosition === 0 && this.rows[0]?.rowDataId === currentRowOnPosition.rowDataId) || false,
    });
  }

  onDragRow(event: Drop<Row>) {
    this.dragRow$.next(event);
  }

  dropInsideLevel(event: Drop<Row>, currentRowOnPosition: Row) {
    if (currentRowOnPosition && event?.data && this.isRowsInSameLevel(currentRowOnPosition, event?.data)) {
      this.rows = this.changeRowPosition(this.rows, event, currentRowOnPosition);
    }
  }

  isRowsInSameLevel(firstRow: Row, secondRow: Row<EntityWithId, unknown>): boolean {
    const firstRowLevel = firstRow?.rowId?.split(ID_SPLITTER).reduce((ac, cur, i, arr) => {
      return ac + (arr.length - 1 !== i ? ID_SPLITTER + cur : '');
    }, '');
    const secondRowLevel = secondRow?.rowId?.split(ID_SPLITTER).reduce((ac, cur, i, arr) => {
      return ac + (arr.length - 1 !== i ? ID_SPLITTER + cur : '');
    }, '');

    return firstRowLevel === secondRowLevel;
  }

  onColumnDragEnd(drop: Drop<Column>) {
    const newConfig = { ...this.config };
    moveItemInArray(newConfig.columnsConfig, drop.prevPosition || 0, drop.newPosition || 0);
    this.config = {
      ...newConfig,
    };
    this.rows = this.rows.map((r) => r);
  }

  isRowParrentOfRow(parentRowChildren: Row[], childRow: Row<EntityWithId, unknown>): boolean {
    let isParent = false;
    for (let i = 0; parentRowChildren?.length > i; i++) {
      if (parentRowChildren[i].rowId === childRow.rowId) {
        isParent = true;
        break;
      }
      if (parentRowChildren[i]?.children?.length) {
        isParent = this.isRowParrentOfRow(parentRowChildren[i].children!, childRow);
        if (isParent) {
          break;
        }
      }
    }
    return isParent;
  }

  dropLevelToLevel(event: Drop<Row>, currentRowOnPosition: Row) {
    if (event?.data?.rowDataId !== currentRowOnPosition?.rowDataId) {
      const updatedRows = this.changeRowPosition(this.rows$.getValue(), event, currentRowOnPosition);
      this.updateRowsPositions.emit(updatedRows);
    }
  }

  changeRowPosition(rows: Row[], droppedRowEvent: Drop<Row>, changedRow: Row): Row[] {
    if (droppedRowEvent.dropInside) {
      let updatedRows = rows.filter((row) => row.rowDataId !== droppedRowEvent?.data.rowDataId);

      updatedRows.map((row) => {
        return removeRowChild(row, droppedRowEvent?.data.rowDataId || '');
      });

      updatedRows.map((row) => {
        return addChildrenToRow(row, changedRow.rowDataId || '', droppedRowEvent?.data);
      });

      return updatedRows;
    }

    return rows.reduce((ac: Row[], r: Row) => {
      let currentAndNextRow: Row[] = [r];

      if (r.rowDataId === droppedRowEvent?.data.rowDataId) {
        currentAndNextRow = [];
      }
      if (r.children?.length && r.expanded) {
        r.children = this.changeRowPosition(r.children, droppedRowEvent, changedRow);
      }
      if (changedRow?.rowDataId === r.rowDataId) {
        if (droppedRowEvent.placeOnTop && this.rows[0].rowDataId === r.rowDataId) {
          currentAndNextRow = [droppedRowEvent.data, r];
        } else {
          currentAndNextRow = [r, droppedRowEvent.data];
        }
      }

      return [...ac, ...currentAndNextRow];
    }, []);
  }

  onRowExpand(row: Row) {
    this.rows = this.rows.map((r) => r);
    this.expandRow.emit(row);
    this.prepareColumns(this.rows$.getValue());
  }

  switchParents(rows: Row[], selectedRow: Row, parentIds: string): Row[] {
    let updatedRows: Row[] = [...rows];
    updatedRows = (function updateRows(rows: Row[]) {
      return rows.map((row) => {
        if (row.rowId === parentIds) {
          const updatedRow = { ...row };
          const hasSelectedChildren = updatedRow.children?.some((ch) => ch.selected === true);
          const hasDeselectedChildren = updatedRow.children?.some((ch) => !ch.selected);
          const hasIndeterminateChild = updatedRow.children?.some((ch) => ch.selected === 'indeterminate');

          if ((hasSelectedChildren && hasDeselectedChildren) || hasIndeterminateChild) {
            updatedRow.selected = 'indeterminate';
          } else {
            updatedRow.selected = selectedRow?.selected;
          }
          return updatedRow;
        }
        if (row.children?.length) {
          row.children = updateRows(row.children);
        }
        return row;
      });
    })(updatedRows);

    return updatedRows;
  }

  switchChildrenRow(rows: Row[], selectedRow: Row): Row[] {
    return rows.map((r) => {
      if (
        (r?.rowId?.split(ID_SPLITTER).length || 0) > (selectedRow?.rowId?.split(ID_SPLITTER).length || 0) &&
        r.rowId?.split(ID_SPLITTER).slice(0, -1).join(ID_SPLITTER) === selectedRow?.rowId
      ) {
        r.selected = selectedRow?.selected;

        if (r?.children?.length) {
          r.children = this.switchChildrenRow(r.children, r);
        }
      } else if (r?.children?.length) {
        r.children = this.switchChildrenRow(r.children, selectedRow);
      }
      return r;
    });
  }

  getFlatRowList(rows: Row[]): Row[] {
    const resultRows: Row[] = [];
    rows.forEach((r) => {
      resultRows.push(r);
      if (r?.children?.length) {
        resultRows.push(...this.getFlatRowList(r.children));
      }
    });
    return resultRows;
  }

  getExpandedFlatRowList(rows: Row[]): Row[] {
    const resultRows: Row[] = [];
    rows.forEach((r) => {
      resultRows.push(r);
      if (r?.children?.length && r?.expanded) {
        resultRows.push(...this.getExpandedFlatRowList(r.children));
      }
    });
    return resultRows;
  }

  isAllRowSelected(rows: Row[]): boolean {
    let isAllSelected = true;
    isAllSelected = this.checkIsRowsSelected(rows, isAllSelected);
    return isAllSelected;
  }
  isAnyRowSelected(rows: Row[]): boolean {
    let isAnySelected = false;
    isAnySelected = this.checkIsAnyRowSelected(rows, isAnySelected);
    return isAnySelected;
  }

  checkIsRowsSelected(rows: Row[], isAllSelected: boolean): boolean {
    for (let i = 0; i < this.rows.length; i++) {
      if (rows[i]?.children?.length) {
        this.checkIsRowsSelected(rows[i]?.children as Row[], isAllSelected);
      }
      if (!rows[i]?.selected) {
        isAllSelected = false;
        break;
      }
    }
    return isAllSelected;
  }
  checkIsAnyRowSelected(rows: Row[], isAnySelected: boolean): boolean {
    for (let i = 0; i < this.rows.length; i++) {
      if (rows[i]?.children?.length) {
        this.checkIsRowsSelected(rows[i]?.children as Row[], isAnySelected);
      }
      if (rows[i]?.selected) {
        isAnySelected = true;
        break;
      }
    }

    return isAnySelected;
  }

  onLoadMore(up?: boolean, keepPage?: boolean): void {
    const { infiniteLoadConfig } = this.config;
    if (infiniteLoadConfig) {
      infiniteLoadConfig.loadMoreItems(up, keepPage);
    }
  }

  onLoadUpVisible(): void {
    const parentHasScroll = this.parentViewportHasScroll();

    if (!this.isLoadUpScrolled && parentHasScroll) {
      this.scrollToTableBody();
      this.isLoadUpScrolled = true;
    } else {
      this.isLoadUpScrolled = false;
      if (!parentHasScroll) {
        this.keepScrollToRow = this.rows[this.rows.length - 1].data?.id;
      }
      this.onLoadMore(true, !parentHasScroll);
    }
  }

  prevScrollTop = 0;
  currentMoveDirection: MoveDirection = 'up';
  debounceUpdateMoveDirection = this.debounce(this.updateMoveDirection, 100);

  onScroll(event: Event) {
    const { scrollTop } = event.target as HTMLElement;
    this.updateMoveDirection(scrollTop);
  }

  updateMoveDirection(scrollTop: number): void {
    this.currentMoveDirection = scrollTop > this.prevScrollTop ? 'up' : 'down';
    this.prevScrollTop = scrollTop;
  }

  parentViewportHasScroll(): boolean {
    const bodyElem = this.tableBodyEl.nativeElement;
    const { bottom } = bodyElem.getBoundingClientRect();
    const parentEl = bodyElem.closest(`[data-scroll-wrapper="${PARENT_SCROLL_WRAPPER_ATTR}"]`);

    if (!parentEl) throw Error('Parent element with an attribute data-scroll-wrapper="scrollWrapperAttr" is not defined');
    let parentRect = parentEl.getBoundingClientRect();
    return bottom + this.topSpinner.nativeElement.clientHeight >= parentRect.bottom;
  }

  scrollToTopAndLoad(): void {
    this.isLoadUpScrolled = false;
    this.onLoadMore(true);
  }

  showTopSpinner(): boolean {
    const { reloading, loadingUp, canLoadUp } = this.infiniteLoadingState;
    return !reloading && loadingUp && canLoadUp;
  }

  showTopInfiniteLoader(): boolean {
    const { reloading, loadingUp, canLoadUp } = this.infiniteLoadingState;
    return canLoadUp && !reloading && !loadingUp;
  }

  scrollToTableBody(): void {
    this.tableBodyEl.nativeElement.scrollIntoView();
    this.isLoadUpScrolled = true;
  }

  scrollToBottomSpinner(): void {
    this.bottomSpinner.nativeElement.scrollIntoView();
  }

  scrollToTopSpinner(): void {
    this.topSpinner.nativeElement.scrollIntoView();
  }

  stopScrollToRow(): void {
    this.keepScrollToRow = undefined;
  }

  debounce(func: FunctionType, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func.bind(this), delay);
    };
  }

  debounceStopScrollToRow = this.debounce(this.stopScrollToRow, 100);

  onRowHidden(event: RowVisibilityDirection): void {
    if (this.keepScrollToRow) {
      this.debounceStopScrollToRow();
      this.tableBody.scrollToRow(this.keepScrollToRow);
      return;
    }
    if (this.config.infiniteLoadConfig) {
      this.config.infiniteLoadConfig.onRowHidden({
        ...event,
        visibilityEvent: {
          ...event.visibilityEvent,
          direction: this.currentMoveDirection,
        },
      });
    }
  }

  onRowVisible(event: RowVisibilityDirection) {
    if (this.config.infiniteLoadConfig) {
      this.config.infiniteLoadConfig.onRowVisible({
        ...event,
        visibilityEvent: {
          ...event.visibilityEvent,
          direction: this.currentMoveDirection,
        },
      });
    }
  }

  onRowHeightInited(event: RowHeightEvent) {
    if (this.config.infiniteLoadConfig) {
      this.config.infiniteLoadConfig.onRowHeightInited(event);
    }
  }
  findRowById(rows: Row<EntityWithId, unknown>[], id: string): Row<EntityWithId, unknown> | null {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.data && row.data.id === id) {
        return row;
      }
      if (row.children) {
        const childRow = this.findRowById(row.children, id);
        if (childRow) {
          return childRow;
        }
      }
    }
    return null;
  }

  removeRowFromTable(rowDataId: string) {
    const rows = this.rows$.getValue().filter((row) => row.rowDataId !== rowDataId);
    rows.map((row) => removeRowChild(row, rowDataId));
    this.updateRowsPositions.emit(rows);
  }

  replaceRowInTable(newRow: Row, newParentId: string, placeOnTop: boolean, updateChildren?: boolean) {
    let rows = this.rows$.getValue();
    if (placeOnTop || newParentId) {
      //removing changed row from the list
      let updatedRows = rows.filter((row) => {
        return row.rowDataId !== newRow?.rowDataId;
      });

      updatedRows.map((row) => {
        return removeRowChild(row, newRow.rowDataId!);
      });
      if (newParentId) {
        updatedRows.map((row) => {
          return addChildrenToRow(row, newParentId, newRow);
        });
      } else if (placeOnTop) {
        updatedRows.unshift(newRow);
      }

      this.updateRowsPositions.emit(updatedRows);
      return;
    }
    if (newRow) {
      rows = rows.map((row) => this.replaceRow(row, newRow, !!updateChildren));
      this.updateRowsPositions.emit(rows);
      return;
    }
  }
  replaceRow(row: Row, newRow: Row, updateChildren: boolean) {
    if (row.rowDataId === newRow.rowDataId) {
      if (row.children) {
        if (!updateChildren) {
          newRow.children = row.children;
          newRow.expanded = row.expanded;
        } else {
          newRow.expanded = row.expanded;
          this.onRowExpand(newRow);
        }
      }
      return newRow;
    }
    if (row.children) {
      row.children = row.children.map((child) => this.replaceRow(child, newRow, !!updateChildren));
    }
    return row;
  }
  selectRows(ids: String[]) {
    if (ids.length) {
      const rows = this.getFlatRowList(this.rows).filter((row) => ids.includes(row?.rowDataId || ''));

      this.switchSelectRow(rows);
      const flatRowList = this.getFlatRowList(this.rows);
      const selectedRows = flatRowList.filter((row) => row.selected === true);
      const selected = this.selectService.getSelected().filter((r) => !flatRowList.some((row) => row.rowDataId === r.rowDataId));

      this.selectService.setSelected([...selected, ...selectedRows]);

      this.refresh$.next(true);
    }
  }

  getMainColumn() {
    const column = this.config.columnsConfig.find((col) => col.required);
    return column ? this.config.columnsConfig.indexOf(column) : 0;
  }

  getMetricColumn(metric: string) {
    const column = this.config.columnsConfig.find((col) => col.metricName === metric);
    return column ? this.config.columnsConfig.indexOf(column) : 1;
  }
}
function removeRowChild(node: Row, id: string) {
  if (!node.children) {
    return;
  }

  node.children = node.children.filter((child) => child.rowDataId !== id);

  for (let child of node.children) {
    removeRowChild(child, id);
  }
}

function addChildrenToRow(row: Row, rowDataId: string, rowToAdd: Row) {
  if (row && row?.rowDataId === rowDataId) {
    if (row.children) {
      row?.children.unshift(rowToAdd);
    } else if (!row.lazyChildren) {
      row.children = [rowToAdd];
    }

    return;
  }
  if (row?.children) {
    for (let child of row.children) {
      addChildrenToRow(child, rowDataId, rowToAdd);
    }
  }
}

export interface TemplateColData {
  template: TemplateRef<ElementRef>;
  columnName: string;
}
