import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { StoresStorage } from '@services/settings';

@Injectable()
export class StoresGuard implements CanActivate {
  constructor(private storesStorage: StoresStorage) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const access = route.data.access;
    return this.storesStorage.initAvailableStores({ requiredScope: access }).then(() => true);
  }
}
