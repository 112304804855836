import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

import { DatepickerRange } from '@app/shared/component/datepicker/datepicker.component';
import { DaysAndTimeFilter } from '@app/shared/component/filter-for-days/filter-for-days.component';
import { formatDate, getPeriodString } from '@app/shared/component/filters-table/filters-table.utils';
import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { allWeekdays, DAYS_OF_WEEK } from '@constants';
import { ParamsService } from '@core/service/params.service';
import { SessionStorage } from '@services/api';
import { StoresStorage } from '@services/settings';
import { DayOfWeek } from '@typings';
import { getDateWithoutOffset, notEmpty } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsFiltersService {
  readonly periodParam: string = 'period';
  readonly storesParam: string = 'stores';
  readonly daysParam: string = 'days';

  readonly comparePeriodParam: string = 'comparePeriods';
  readonly compareDaysParam: string = 'compareDays';
  readonly firstStoresSegmentParam: string = 'firstSegment';
  readonly secondStoresSegmentParam: string = 'secondSegment';

  #selectedStoreIds = new BehaviorSubject<string[]>(this.getFilters('analyticStores'));
  stores$: Observable<MenuItem[]> = this.storesStorage.stores$;
  selectedStores$: Observable<MenuItem[]> = this.#getSelectedStores();
  selectedStoreIds$: Observable<string[]> = this.selectedStores$.pipe(map((stores) => stores.map((s) => s.id).filter(notEmpty)));
  selectedStoreIdsSet$: Observable<Set<string>> = this.selectedStoreIds$.pipe(map((storeIds) => new Set(storeIds)));

  selectedCompareStoreIds = new BehaviorSubject<string[][] | null>(this.changeCompareStores());
  selectedCompareStoreIdsSet$: Observable<Set<string>> = this.selectedCompareStoreIds.pipe(
    map((storeIds) => {
      return storeIds && storeIds[1] ? new Set(storeIds[1]) : new Set();
    }),
  );

  selectedPeriod$ = new BehaviorSubject<DateRange<Date> | null>(this.getPeriodFilters());
  comparePeriods$ = new BehaviorSubject<DateRange<Date>[] | null>(this.changeComparePeriod());

  initDaysAndTimeFilter = {
    daysOfWeek: [],
    hourRange: null,
  };
  daysAndTimeFilter$ = new BehaviorSubject<DaysAndTimeFilter | null>(this.getDaysAndTimeFilter()[0]);
  compareDaysAndTimeFilter$ = new BehaviorSubject<DaysAndTimeFilter[] | null>(this.changeCompareDays());

  filtersDescription$ = combineLatest([this.selectedStores$, this.selectedPeriod$, this.stores$]).pipe(
    map(([selectedStores, period, stores]) => {
      const storesStr =
        !selectedStores.length || selectedStores.length === stores.length
          ? 'Все точки продаж'
          : selectedStores.map((store) => store.label).join(', ');
      return `${getPeriodString(period)} • ${storesStr}`;
    }),
  );

  constructor(private sessionStorage: SessionStorage, private paramsService: ParamsService, private storesStorage: StoresStorage) {}

  getFilters(key: string): string[] {
    return (localStorage.getItem(key) || '').split(',');
  }

  getPeriodFilters(): DateRange<Date> | null {
    const period = localStorage.getItem('analyticPeriod') ? localStorage.getItem('analyticPeriod')!.split('|') : null;
    let datepickerRange: DateRange<Date> | null = null;

    if (period) {
      datepickerRange = new DateRange(getDateWithoutOffset(period[0]), getDateWithoutOffset(period[1]));
    }

    return datepickerRange;
  }

  #getSelectedStores(): Observable<MenuItem[]> {
    return combineLatest([this.stores$, this.#selectedStoreIds]).pipe(
      map(([stores, selectedIds]) => {
        return selectedIds[0] === '' || selectedIds.length === stores.length
          ? [...stores]
          : Array.from(selectedIds)
              .map((id) => stores.find((store) => store.id === id))
              .filter(notEmpty);
      }),
    );
  }

  setStores(selectedStores: Set<string>): void {
    this.resetCompareStores();
    this.paramsService.setParams([{ name: this.storesParam, value: [...selectedStores].join('|') }]);
    this.#selectedStoreIds.next([...selectedStores]);
    localStorage.setItem('analyticStores', [...selectedStores].join(','));
  }

  setCompareStores(selectedStores: Set<string>[]): void {
    this.resetComparePeriods();
    this.resetCompareDays();
    this.paramsService.setParams([{ name: this.storesParam, value: '' }]);
    localStorage.setItem(this.firstStoresSegmentParam, [...selectedStores[0]].join(','));
    localStorage.setItem(this.secondStoresSegmentParam, [...selectedStores[1]].join(','));
    this.selectedCompareStoreIds.next([[...selectedStores[0]], [...selectedStores[1]]]);
    this.#selectedStoreIds.next([...selectedStores[0]]);
  }

  changeCompareStores(): string[][] | null {
    const firstSegment = this.getFilters(this.firstStoresSegmentParam);
    const secondSegment = this.getFilters(this.secondStoresSegmentParam);
    if (firstSegment && firstSegment[0] && firstSegment[0] !== 'null' && secondSegment && secondSegment[0] && secondSegment[0] !== 'null') {
      this.#selectedStoreIds.next(firstSegment);
      return [firstSegment, secondSegment];
    }
    return null;
  }

  resetStores(): void {
    this.#selectedStoreIds.next([]);
  }

  resetCompareStores() {
    localStorage.setItem(this.firstStoresSegmentParam, '');
    localStorage.setItem(this.secondStoresSegmentParam, '');
    this.selectedCompareStoreIds.next(null);
  }

  setPeriod(period: DatepickerRange): void {
    let currentPeriod: DatepickerRange = period;

    if (!period) {
      currentPeriod = {
        start: new Date(),
        end: new Date(),
      };
    }

    if (currentPeriod && currentPeriod.start && currentPeriod.end) {
      this.paramsService.setParams([{ name: this.comparePeriodParam, value: '' }]);
      this.paramsService.setParams([
        {
          name: this.periodParam,
          value: [formatDate(currentPeriod.start), formatDate(currentPeriod.end)].join('|'),
        },
      ]);
      this.comparePeriods$.next(null);
      this.selectedPeriod$.next(this.paramsService.getDateRangeFromParam(this.periodParam));
      localStorage.setItem('analyticPeriod', [formatDate(currentPeriod.start), formatDate(currentPeriod.end)].join('|'));
    }
  }

  setComparePeriods(periods: DatepickerRange[]) {
    if (this.selectedCompareStoreIds.getValue()) {
      this.resetCompareStores();
    }
    this.resetCompareDays();
    let comparePeriods = periods;

    if (!periods) {
      comparePeriods = [
        { start: new Date(), end: new Date() },
        { start: new Date(), end: new Date() },
      ];
    }
    if (comparePeriods && comparePeriods[0].start && comparePeriods[0].end && comparePeriods[1].start && comparePeriods[1].end) {
      this.paramsService.setParams([
        {
          name: this.comparePeriodParam,
          value: [
            formatDate(comparePeriods[0].start),
            formatDate(comparePeriods[0].end),
            formatDate(comparePeriods[1].start),
            formatDate(comparePeriods[1].end),
          ].join('|'),
        },
      ]);
      this.paramsService.setParams([{ name: this.periodParam, value: '' }]);
      this.comparePeriods$.next(this.paramsService.getDateCompareRangeFromParam());
      this.selectedPeriod$.next(this.paramsService.getDateCompareRangeFromParam()![0]);
    }
  }

  changePeriod(): DateRange<Date> | null {
    return this.paramsService.getDateRangeFromParam(this.periodParam);
  }

  changeComparePeriod(): DateRange<Date>[] | null {
    return this.paramsService.getDateCompareRangeFromParam();
  }

  resetPeriod(): void {
    this.selectedPeriod$.next(this.changePeriod());
  }

  resetComparePeriods() {
    this.paramsService.setParams([{ name: this.comparePeriodParam, value: '' }]);
    this.comparePeriods$.next(null);
  }

  getDaysAndTimeFilter(compare: boolean = false): DaysAndTimeFilter[] {
    const days = this.paramsService.getDaysAndTimeFilterFromParams(this.daysParam);
    const init = days || this.initDaysAndTimeFilter;
    if (compare) {
      return [this.initDaysAndTimeFilter, this.initDaysAndTimeFilter];
    }
    return [init];
  }

  setDaysAndTime(daysAndTime: DaysAndTimeFilter) {
    this.paramsService.setParams([
      {
        name: this.daysParam,
        value: [
          ...daysAndTime.daysOfWeek,
          daysAndTime.hourRange ? daysAndTime.hourRange.timeFrom : this.sessionStorage.getEndWorkTime(),
          daysAndTime.hourRange ? daysAndTime.hourRange.timeTo : this.sessionStorage.getEndWorkTime(),
        ].join('|'),
      },
    ]);
    this.paramsService.setParams([{ name: this.compareDaysParam, value: '' }]);
    this.compareDaysAndTimeFilter$.next(null);
    this.daysAndTimeFilter$.next(daysAndTime);
  }

  setCompareDaysAndTime(daysAndTime: DaysAndTimeFilter[]) {
    if (this.selectedCompareStoreIds.getValue()) {
      this.resetCompareStores();
    }
    this.resetComparePeriods();

    const value = daysAndTime
      .map((item) =>
        [
          ...item.daysOfWeek,
          item.hourRange ? item.hourRange.timeFrom : this.sessionStorage.getEndWorkTime(),
          item.hourRange ? item.hourRange.timeTo : this.sessionStorage.getEndWorkTime(),
        ].join('_'),
      )
      .join('|');

    this.paramsService.setParams([
      {
        name: this.compareDaysParam,
        value,
      },
    ]);

    this.compareDaysAndTimeFilter$.next(daysAndTime);
    this.paramsService.setParams([{ name: this.daysParam, value: '' }]);
    this.daysAndTimeFilter$.next(daysAndTime[0]);
  }

  changeCompareDays(): DaysAndTimeFilter[] | null {
    return this.paramsService.getCompareDaysAndTimeFilterFromParams(this.compareDaysParam);
  }

  resetCompareDays() {
    this.paramsService.setParams([{ name: this.compareDaysParam, value: '' }]);
    this.compareDaysAndTimeFilter$.next(null);
  }

  getStringDays(weekdays: DayOfWeek[], timeStart: string, timeEnd: string) {
    let sortedIndices = weekdays.map((day) => allWeekdays.indexOf(day)).sort((a, b) => a - b);
    if (sortedIndices.length === 0) {
      sortedIndices = allWeekdays.map((day, index) => index);
    }

    let isConsecutive = true;
    for (let i = 1; i < sortedIndices.length; i++) {
      if (sortedIndices[i] !== sortedIndices[i - 1] + 1) {
        isConsecutive = false;
        break;
      }
    }

    let res: string;
    if (isConsecutive) {
      if (sortedIndices.length === 1) {
        res = `${DAYS_OF_WEEK[sortedIndices[0]].name}`;
      } else {
        const startDay = DAYS_OF_WEEK[sortedIndices[0]].name;
        const endDay = DAYS_OF_WEEK[sortedIndices[sortedIndices.length - 1]].name;
        res = `${startDay} – ${endDay}`;
      }
    } else {
      res = sortedIndices.map((index) => DAYS_OF_WEEK[index].name).join(', ');
    }

    if (timeStart.length === 5 && timeEnd.length === 5) {
      res += `, ${timeStart} – ${timeEnd}`;
    }

    return res;
  }
}
