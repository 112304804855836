import { NavDrawerItem } from '@typings';

export enum AnalyticsRoute {
  dashboardReport = 'dashboard-report',
  dashboardClientReport = 'dashboard-client-report',
  dashboardEmployees = 'dashboard-employees',
  dashboardTables = 'dashboard-tables',
  abcReport = 'abc-report',
  categoriesReport = 'categories-report',
  surchargeReport = 'surcharge-report',
  consolidatedReport = 'consolidated-report',
  employeesReport = 'employees-report',
  paymentSystemsReport = 'payment-systems-report',
  tablesReport = 'tables-report',
  productsReport = 'products-report',
  storesReport = 'stores-report',
  workshopsReport = 'workshops-report',
  clientsReport = 'clients-report',
  clientGroupsReport = 'client-groups-report',
}

export enum AnalyticsRouteName {
  dashboardReport = 'Обзор организации',
  dashboardClientReport = 'Обзор клиентов',
  dashboardEmployees = 'Обзор по сотрудникам',
  dashboardTables = 'Обзор по столам',
  abcReport = 'ABC-отчет',
  categoriesReport = 'Отчет по категориям',
  surchargeReport = 'Отчет по наценкам',
  consolidatedReport = 'Отчет по продажам',
  employeesReport = 'Отчет по сотрудникам',
  paymentSystemsReport = 'Отчет по способам оплаты',
  tablesReport = 'Отчет по столам',
  productsReport = 'Отчет по товарам',
  storesReport = 'Отчет по точкам продаж',
  workshopsReport = 'Отчет по цехам',
  clientsReport = 'Отчет по клиентам',
  clientGroupsReport = 'Отчет по группам клиентов',
}

export enum AnalyticsAccess {
  dashboardReport = 'analytics.overviewDashboard',
  dashboardClientReport = 'analytics.customersDashboard',
  dashboardEmployees = 'analytics.employeesDashboard',
  dashboardTables = 'analytics.tablesDashboard',
  abcReport = 'analytics.abcReport',
  categoriesReport = 'analytics.categoryReport',
  surchargeReport = 'analytics.marginReport',
  consolidatedReport = 'analytics.salesReport',
  employeesReport = 'analytics.employeesReport',
  paymentSystemsReport = 'analytics.paySystemReport',
  tablesReport = 'analytics.tablesReport',
  productsReport = 'analytics.productsReport',
  storesReport = 'analytics.storesReport',
  workshopsReport = 'analytics.workshopReport',
  clientsReport = 'analytics.customersReport',
  clientGroupsReport = 'analytics.customersGroupReport',
}

export enum AnalyticsRouteParam {
  productId = 'idProduct',
}

export const ANALYTICS_ITEM_ABC = {
  title: AnalyticsRouteName.abcReport,
  route: AnalyticsRoute.abcReport,
  access: AnalyticsAccess.abcReport,
};

export const ANALYTICS_ITEM_EMPLOYEES_REPORT = {
  title: AnalyticsRouteName.employeesReport,
  route: AnalyticsRoute.employeesReport,
  access: AnalyticsAccess.employeesReport,
};

export const ANALYTICS_ITEM_PRODUCTS = {
  title: AnalyticsRouteName.productsReport,
  route: AnalyticsRoute.productsReport,
  access: AnalyticsAccess.productsReport,
  routes: [
    {
      title: '',
      route: AnalyticsRouteParam.productId,
      keepUrlParams: true,
    },
  ],
};

export const ANALYTICS_ITEM_CATEGORIES = {
  title: AnalyticsRouteName.categoriesReport,
  route: AnalyticsRoute.categoriesReport,
  access: AnalyticsAccess.categoriesReport,
};

export const ANALYTICS_ITEM_DASHBOARD: NavDrawerItem = {
  title: AnalyticsRouteName.dashboardReport,
  route: AnalyticsRoute.dashboardReport,
  access: AnalyticsAccess.dashboardReport,
};

export const ANALYTICS_ITEM_DASHBOARD_CLIENT: NavDrawerItem = {
  title: AnalyticsRouteName.dashboardClientReport,
  route: AnalyticsRoute.dashboardClientReport,
  access: AnalyticsAccess.dashboardClientReport,
};

export const ANALYTICS_ITEM_SURCHARGE: NavDrawerItem = {
  title: AnalyticsRouteName.surchargeReport,
  route: AnalyticsRoute.surchargeReport,
  access: AnalyticsAccess.surchargeReport,
};

export const ANALYTICS_ITEM_DASHBOARD_EMPLOYEES: NavDrawerItem = {
  title: AnalyticsRouteName.dashboardEmployees,
  route: AnalyticsRoute.dashboardEmployees,
  access: AnalyticsAccess.dashboardEmployees,
};

export const ANALYTICS_ITEM_PAYMENT_SYSTEMS: NavDrawerItem = {
  title: AnalyticsRouteName.paymentSystemsReport,
  route: AnalyticsRoute.paymentSystemsReport,
  access: AnalyticsAccess.paymentSystemsReport,
};

export const ANALYTICS_ITEM_CONSOLIDATED: NavDrawerItem = {
  title: AnalyticsRouteName.consolidatedReport,
  route: AnalyticsRoute.consolidatedReport,
  access: AnalyticsAccess.consolidatedReport,
};

export const ANALYTICS_ITEM_DASHBOARD_TABLES: NavDrawerItem = {
  title: AnalyticsRouteName.dashboardTables,
  route: AnalyticsRoute.dashboardTables,
  access: AnalyticsAccess.dashboardTables,
};

export const ANALYTICS_ITEM_TABLES: NavDrawerItem = {
  title: AnalyticsRouteName.tablesReport,
  route: AnalyticsRoute.tablesReport,
  access: AnalyticsAccess.tablesReport,
};

export const ANALYTICS_ITEM_WORKSHOPS_REPORT: NavDrawerItem = {
  title: AnalyticsRouteName.workshopsReport,
  route: AnalyticsRoute.workshopsReport,
  access: AnalyticsAccess.workshopsReport,
};

export const ANALYTICS_ITEM_STORES_REPORT: NavDrawerItem = {
  title: AnalyticsRouteName.storesReport,
  route: AnalyticsRoute.storesReport,
  access: AnalyticsAccess.storesReport,
};

export const ANALYTICS_ITEM_CLIENTS_REPORT: NavDrawerItem = {
  title: AnalyticsRouteName.clientsReport,
  route: AnalyticsRoute.clientsReport,
  access: AnalyticsAccess.clientsReport,
};

export const ANALYTICS_ITEM_CLIENT_GROUPS_REPORT: NavDrawerItem = {
  title: AnalyticsRouteName.clientGroupsReport,
  route: AnalyticsRoute.clientGroupsReport,
  access: AnalyticsAccess.clientGroupsReport,
};

export enum ANALYTICS_SUB_TITLE_ITEMS {
  sale = 'Продажи',
  marketing = 'Маркетинг',
  employee = 'Персонал',
  tables = 'Столы',
  finance = 'Финансы',
}

export const ANALYTICS_SALE_CHILD_ITEMS: NavDrawerItem[] = [
  ANALYTICS_ITEM_ABC,
  ANALYTICS_ITEM_CATEGORIES,
  ANALYTICS_ITEM_SURCHARGE,
  ANALYTICS_ITEM_CONSOLIDATED,
  ANALYTICS_ITEM_PRODUCTS,
  ANALYTICS_ITEM_STORES_REPORT,
  ANALYTICS_ITEM_WORKSHOPS_REPORT,
];

export const ANALYTICS_MARKETING_CHILD_ITEMS = [
  ANALYTICS_ITEM_DASHBOARD_CLIENT,
  ANALYTICS_ITEM_CLIENT_GROUPS_REPORT,
  ANALYTICS_ITEM_CLIENTS_REPORT,
];

export const ANALYTICS_EMPLOYEE_CHILD_ITEMS: NavDrawerItem[] = [ANALYTICS_ITEM_DASHBOARD_EMPLOYEES, ANALYTICS_ITEM_EMPLOYEES_REPORT];

export const ANALYTICS_TABLES_CHILD_ITEMS: NavDrawerItem[] = [ANALYTICS_ITEM_DASHBOARD_TABLES, ANALYTICS_ITEM_TABLES];

export const ANALYTICS_FINANCE_CHILD_ITEMS: NavDrawerItem[] = [ANALYTICS_ITEM_PAYMENT_SYSTEMS];

export const ANALYTICS_CHILD_ITEMS: NavDrawerItem[] = [
  ANALYTICS_ITEM_DASHBOARD,
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.sale,
    routes: ANALYTICS_SALE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.marketing,
    routes: ANALYTICS_MARKETING_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.employee,
    routes: ANALYTICS_EMPLOYEE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.tables,
    routes: ANALYTICS_TABLES_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
  {
    title: ANALYTICS_SUB_TITLE_ITEMS.finance,
    routes: ANALYTICS_FINANCE_CHILD_ITEMS,
    isSubRouteClose: true,
    isSubTitle: true,
  },
];
