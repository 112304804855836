import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { getOrDefault, MetricModel } from '@app/shared/component/metric/metric.model';
import { TabComponent } from '@app/shared/component/tab/tab.component';

const STRETCH_TABS_ATTRIBUTE = 'mat-stretch-tabs';

@Component({
  selector: 'nm-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements AfterViewInit, OnChanges {
  @Input() selectedIndex: number | null = null;
  @Input() tabsWidth: 'fit-content' | 'proportional' = 'fit-content';
  @Input() iconDirection: 'horizontal' | 'vertical' = 'horizontal';
  @Input() sidenavMode: boolean = false;
  @Input() testId: string;
  @Input() isLoading: boolean = false;
  @Input() disablePagination: boolean = false;

  @Output() selectedIndexChange = new EventEmitter<number>();

  @ViewChild('matTabGroup') matTab: MatTabGroup;
  @ContentChildren(TabComponent, { descendants: true }) public tabs: QueryList<TabComponent>;
  previousTouch: number = 0;

  ngAfterViewInit(): void {
    if (this.tabsWidth !== 'proportional') {
      this.matTab._elementRef.nativeElement.removeAttribute('mat-stretch-tabs');
    }

    if (this.sidenavMode) {
      document
        .getElementsByTagName('mat-tab-header')[0]
        .setAttribute('class', ' mat-tab-header mat-tab-header-pagination-controls-enabled');
      document.getElementsByClassName('mat-tab-header-pagination-after')[0].removeAttribute('ng-reflect-disabled');
      document.getElementsByClassName('mat-tab-header-pagination-after')[0].removeAttribute('disabled');
      document
        .getElementsByClassName('mat-tab-header-pagination-after')[0]
        .setAttribute(
          'class',
          'mat-ripple mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4 header-pagination-box-shadow',
        );

      document
        .getElementsByClassName('mat-tab-header-pagination-before')[0]
        .setAttribute(
          'class',
          'mat-ripple mat-tab-header-pagination mat-tab-header-pagination-before mat-elevation-z4 header-pagination-box-shadow',
        );
      document.getElementsByTagName('nm-section-header')[0].setAttribute('style', 'max-width: 540px');
    }

    if (this.disablePagination) {
      document.getElementsByClassName('mat-tab-header-pagination-before')[0].remove();
      document.getElementsByClassName('mat-tab-header-pagination-after')[0].remove();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('tabsWidth' in changes && !changes.tabsWidth.firstChange && this.matTab) {
      if (changes.tabsWidth.currentValue === 'proportional' && changes.tabsWidth.previousValue !== 'proportional') {
        this.matTab._elementRef.nativeElement.setAttribute(STRETCH_TABS_ATTRIBUTE, '');
      }

      if (changes.tabsWidth.currentValue === 'fit-content' && changes.tabsWidth.previousValue !== 'fit-content') {
        this.matTab._elementRef.nativeElement.removeAttribute(STRETCH_TABS_ATTRIBUTE);
      }
    }
  }

  get tabGroupClasses(): string[] {
    const classes = ['nm-tab-group'];

    if (this.iconDirection === 'vertical') {
      classes.push('nm-tab-group-vertical');
    }

    if (this.isLoading) {
      classes.push('loading');
    }

    if (this.disablePagination) {
      classes.push('disable-pagination');
    }

    return classes;
  }

  get tabLabelClasses(): string[] {
    const classes = ['tab-label'];

    if (this.iconDirection === 'vertical') {
      classes.push('tab-label-vertical');
    } else {
      classes.push('tab-label-horizontal');
    }

    return classes;
  }

  blur(tab: HTMLDivElement) {
    const activeElement = tab.ownerDocument.activeElement;
    const tabElement = tab?.parentElement?.parentElement;

    if (activeElement === tabElement) {
      tabElement?.blur();
    }
  }

  onScroll(event: TouchEvent) {
    if (!this.disablePagination) {
      event.preventDefault();
      const currentTouch = event.touches[0].pageX;

      const afterButton = document.getElementsByClassName('mat-tab-header-pagination-after')[0] as HTMLButtonElement;
      const beforeButton = document.getElementsByClassName('mat-tab-header-pagination-before')[0] as HTMLButtonElement;
      if (this.previousTouch && this.previousTouch > currentTouch) {
        afterButton.click();
        afterButton.click();
      } else {
        beforeButton.click();
      }

      this.previousTouch = currentTouch;
    }
  }

  onSelectedIndexChange(index: number): void {
    this.selectedIndexChange.emit(index);
  }

  getOrDefault<K extends keyof MetricModel = keyof MetricModel>(item: MetricModel, field: K): MetricModel[K] {
    return getOrDefault(item, field);
  }
}
