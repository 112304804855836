import { gql } from 'apollo-angular';

export const fragmentUserStoreRole = gql`
  fragment UserStoreRole on UserStoreRole {
    id
    allStores
    role {
      id
      name
      description
      scopes
    }
    store {
      id
      name
    }
  }
`;

export const fragmentUser = gql`
  fragment User on User {
    id
    scopes
    accountId
    workEmail
    firstName
    lastName
    middleName
    workPhone
    vatin
    snils
    imageId
    medicalBookExpiresAt
    dateOfBirth
    dateOfEmployment
    address
    storeRoles {
      ...UserStoreRole
    }
    active
    pin
    lastPosSignInTime
    lastActivityTime
    inviteStatus
  }
  ${fragmentUserStoreRole}
`;

export const queryUsers = gql`
  query users($filter: UserFilterInput, $pageRequestInput: PageRequestInput!) {
    users(filter: $filter, pageRequestInput: $pageRequestInput) {
      total
      pageNumber
      pageSize
      totalPages
      content {
        ...User
      }
    }
  }
  ${fragmentUser}
`;

export const queryIamUser = gql`
  query iamUser($organizationId: UUID!) {
    iamUser(organizationId: $organizationId) {
      ...User
    }
  }
  ${fragmentUser}
`;

export const queryUser = gql`
  query user($id: UUID!) {
    user(id: $id) {
      ...User
    }
  }
  ${fragmentUser}
`;

export const mutationInviteUser = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      status {
        errorMessage
        result
      }
      createdUser {
        id
        workEmail
        firstName
        lastName
        middleName
        workPhone
        vatin
        snils
        imageId
        medicalBookExpiresAt
        dateOfBirth
        dateOfEmployment
        address
        active
      }
    }
  }
`;

export const mutationUpdateUser = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status {
        errorMessage
        result
      }
      updatedUser {
        ...User
      }
    }
  }
  ${fragmentUser}
`;

export const mutationAddUserRoles = gql`
  mutation addUserRoles($id: UUID!, $roleIds: [UUID!]!) {
    addUserRoles(id: $id, roleIds: $roleIds) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationRemoveUserRoles = gql`
  mutation removeUserRoles($id: UUID!, $roleIds: [UUID!]!) {
    removeUserRoles(id: $id, roleIds: $roleIds) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationAddUserStoreRoles = gql`
  mutation addUserStoreRoles($userId: UUID!, $storeRoles: [UserStoreRoleInput!]!) {
    addUserStoreRoles(userId: $userId, storeRoles: $storeRoles) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationRemoveUserStoreRoles = gql`
  mutation removeUserStoreRoles($storeRoleIds: [UUID!]!) {
    removeUserStoreRoles(storeRoleIds: $storeRoleIds) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationDeleteUser = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(id: $id) {
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationChangeUserPin = gql`
  mutation refreshUserPin($userId: UUID!) {
    refreshUserPin(userId: $userId) {
      pin
      status {
        errorMessage
        result
      }
    }
  }
`;

export const queryUserScopes = gql`
  query userScopes($filter: UserScopesFilterInput, $page: PageRequestInput!) {
    userScopes(filter: $filter, page: $page) {
      content {
        allStores
        roleId
        roleName
        scope
        userScopeId
        stores {
          id
          name
        }
        user {
          id
          firstName
          lastName
          middleName
          workEmail
        }
      }
      pageNumber
      pageSize
      total
      totalPages
    }
  }
`;

export const mutationAddUserScopes = gql`
  mutation addUserScopes($input: AddUserScopesInput!) {
    addUserScopes(input: $input) {
      userScopeIds
      status {
        errorMessage
        result
      }
    }
  }
`;

export const mutationRemoveUserScopes = gql`
  mutation removeUserScopes($input: RemoveUserScopesInput!) {
    removeUserScopes(input: $input) {
      status {
        errorMessage
        result
      }
    }
  }
`;
