import { StrictDictionary } from '@typings';

export const GRAPH_HEIGHT: StrictDictionary<string, string> = {
  SMALL: 'SMALL',
  MIDDLE: 'MIDDLE',
  BIG: 'BIG',
  BIGGEST: 'BIGGEST',
};

export const GRAPH_HEIGHT_STRING: StrictDictionary<string, string> = {
  SMALL: 'Маленький',
  MIDDLE: 'Средний',
  BIG: 'Большой',
  BIGGEST: 'Очень большой',
};

export const GRAPH_HEIGHT_STRING_SIZE: StrictDictionary<string, string> = {
  SMALL: '180px',
  MIDDLE: '300px',
  BIG: '420px',
  BIGGEST: '540px',
};

export const GRAPH_GROUPING: StrictDictionary<string, string> = {
  AUTO: 'AUTO',
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const GRAPH_GROUPING_STRING: StrictDictionary<string, string> = {
  AUTO: 'Автоматическая',
  HOUR: 'По часам',
  DAY: 'По дням',
  WEEK: 'По неделям',
  MONTH: 'По месяцам',
};
