import { DateRange } from '@angular/material/datepicker';
import dayjs from 'dayjs';

import { DatepickerRange } from '@app/shared/component/datepicker/datepicker.component';
import { DATE_FORMAT, DATE_ISO } from '@constants';
import { DateRangeInput } from '@typings';

export const countdown = (diff: number, finished: string = ''): string => {
  if (diff < 0) {
    return finished;
  }

  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const getDateFromStr = (date: string, endOfDay = false): Date => {
  const [year, month, day] = date.split('/');
  if (endOfDay) {
    return new Date(+year, +month - 1, +day, 23, 59, 59);
  }
  return new Date(+year, +month - 1, +day);
};

export const dateOffsetFix = (value?: string | number) => {
  const raw = dayjs(value);
  const offset = raw.utcOffset() * 60 * 1000;
  return raw.subtract(offset);
};

export const datepickerPeriodToRangeInput = (period: DatepickerRange | null, withISO: boolean = false): DateRangeInput | null => {
  if (period && period.start) {
    return {
      dateFrom: dayjs(period.start).format(withISO ? DATE_ISO : DATE_FORMAT),
      dateTo: dayjs(period?.end || period.start).format(withISO ? DATE_ISO : DATE_FORMAT),
    };
  }
  return null;
};

export const rangeInputToDateRange = (rangeInput: DateRangeInput | undefined): DateRange<Date> | null => {
  if (!rangeInput) return null;
  return new DateRange<Date>(new Date(rangeInput.dateFrom), new Date(rangeInput.dateTo));
};

export const getDateWithoutOffset = (dateStr: string): Date => {
  const date = new Date(dateStr);
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  date.setDate(day);
  date.setFullYear(year);
  date.setMonth(month);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const dateWithoutOffset = (dateStr: string): string => {
  let date = new Date(dateStr);
  let timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
  let correctDate = new Date(timestamp);
  correctDate.setUTCHours(0, 0, 0, 0);
  return correctDate.toISOString();
};
