<div class="nm-metric" [attr.data-test-option-id]="testId" [style.min-width]="isGrid ? '' : '140px'">
  <span *ngIf="!isLoading">
    <div *ngIf="title || helpText" class="title-row">
      <span *ngIf="title" class="title" [title]="title">{{ title }}</span>

      <nm-svg-icon
        *ngIf="helpText"
        class="help-icon"
        [size]="14"
        name="help"
        nmTooltip
        [TooltipContent]="helpText"
      ></nm-svg-icon>
    </div>

    <div *ngIf="value || secondaryValue" class="value-row">
      <span *ngIf="value" class="value" [title]="value">{{ value | numberSeparate }}</span>

      <div
        *ngIf="secondaryValue"
        class="secondary-value-container"
        [ngClass]="{
          plain: secondaryValueColor === 'plain',
          error: secondaryValueColor === 'error',
          success: secondaryValueColor === 'success'
        }"
        [title]="secondaryValue"
      >
        <nm-svg-icon class="secondary-value-icon" [size]="16" [name]="secValueIcon"></nm-svg-icon>

        <span class="secondary-value">{{ secondaryValue | numberSeparate }}</span>
      </div>

      <div class="compare" *ngIf="firstCompareValue && secondCompareValue && (bos.isMobile$ | async) === false">
        <nm-widget-comparison
          [firstValue]="firstCompareValue"
          [secondValue]="secondCompareValue"
          [percentV2]="percentV2"
        ></nm-widget-comparison>
      </div>
    </div>

    <div
      class="compare"
      *ngIf="firstCompareValue && secondCompareValue && (bos.isMobile$ | async) === true"
      [style.margin]="'0px'"
    >
      <nm-widget-comparison
        [firstValue]="firstCompareValue"
        [secondValue]="secondCompareValue"
        [percentV2]="percentV2"
      ></nm-widget-comparison>
    </div>

    <span *ngIf="description" class="description" [title]="description">{{ description | numberSeparate }}</span>
  </span>

  <div *ngIf="isLoading" class="skeleton-wrapper">
    <nm-skeleton class="title-loading"></nm-skeleton>
    <nm-skeleton class="value-loading"></nm-skeleton>
  </div>
</div>
