import { MenuItem } from '@app/shared/component/menu-item/menu-item.model';
import { ConsolidatedReportMetric, ConsolidatedReportMetricNames, ConsolidatedReportSortFieldsStrict } from '@typings';

export const CONSOLIDATED_REPORT_METRICS: ConsolidatedReportMetricNames = {
  revenue: 'Выручка',
  profit: 'Прибыль',
  ordersCount: 'Заказы',
  guestCount: 'Гости',
};

export const CONSOLIDATED_REPORT_METRICS_COMPARE = {
  empty: 'Выберите метрику',
  revenue: 'Выручка',
  profit: 'Прибыль',
  ordersCount: 'Заказы',
  guestCount: 'Гости',
};

export const getConsolidatedMetricsReportItems = (compareMetric: ConsolidatedReportMetric): MenuItem[] => {
  return Object.keys(CONSOLIDATED_REPORT_METRICS)
    .filter((metric) => (!!compareMetric && metric !== compareMetric) || !compareMetric)
    .map((metric) => ({
      id: metric,
      type: 'item',
      label: CONSOLIDATED_REPORT_METRICS[metric],
    }));
};

export const getConsolidatedMetricsCompareWidgetNames = (currentMetric: ConsolidatedReportMetric): MenuItem[] => {
  return Object.keys(CONSOLIDATED_REPORT_METRICS_COMPARE)
    .filter((metric) => metric !== currentMetric)
    .map((metric) => ({
      id: metric,
      label: CONSOLIDATED_REPORT_METRICS_COMPARE[metric],
      type: 'item',
    }));
};

export const getConsolidatedMetricName = (metric: ConsolidatedReportMetric): string => CONSOLIDATED_REPORT_METRICS[metric];

export const CONSOLIDATED_REPORT_SORT_FIELD: ConsolidatedReportSortFieldsStrict = {
  TIMESTAMP: 'TIMESTAMP',
  REVENUE: 'REVENUE',
  DISCOUNT: 'DISCOUNT',
  PROFIT: 'PROFIT',
  ORDERS_COUNT: 'ORDERS_COUNT',
  AVERAGE_CHECK: 'AVERAGE_CHECK',
};
