import { ChangeDetectionStrategy, Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';

import { MetricModel } from '@app/shared/component/metric/metric.model';
import { SVG_ICONS_TYPE } from '@constants';

@Component({
  selector: 'nm-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() icon: SVG_ICONS_TYPE;
  @Input() metric: MetricModel | null = null;

  @ViewChild('content') public contentTemplate: TemplateRef<Component | ElementRef | Element>;
}
